<template>
  <div>
    <div>
      <create-watch-modal :create-watch-list-state="createWatchListState" v-on:close="toggleModal"
        v-on:createWatchList="handleCreateWatchList" :symbolList="symbolDataList" :userId="selectedWatchListuserID"
        :watchId="selectedWatchListID" />

      <CopyToWatchlist :create-copy-watch-list-state="copyModalState" :showCopyMsg="showCopyMsg"
        :showCopyResponseMsg="showCopyResponseMsg" v-on:closeCopyModal="toggleCopyModal"
        :watchlistData="watchListApi_Data" :symbols="userSelectedTableRecords" @copySymbol="handleCopySymbol" />

      <SaveTableColumn v-on:close="closeColumnListModal" :tableColumns="newTableColumn"
        :openSaveModal="openSaveColumnModal" />


      <MoveToWatchlist :create-move-watch-list-state="moveModalState" v-on:closeMoveModal="toggleMoveModal"
        :showMoveMsg="showMoveMsg" :showMoveResponseMsg="showMoveResponseMsg" :watchlistData="watchListApi_Data"
        :previousId="currentWatchList._id" :symbols="userSelectedTableRecords" @moveSymbol="handleMoveSymbol" />

      <add-symbol-modal :add-symbols-state="addSymbolsState" v-on:close="toggleModal"
        v-on:fetchUpdatedSymbolList="handleUpdatedWatchListSymbols" :userId="selectedWatchListuserID"
        :watchId="selectedWatchListID" />

      <!-- 
      <add-column-modal :add-columns-state="addColumnsState" v-on:close="toggleModal"
        v-on:fetchUpdatedSymbolList="handleUpdatedWatchListSymbols" :userId="selectedWatchListuserID"
        :watchId="selectedWatchListID" /> -->

      <RemoveWatchlistModal v-on:close="toggleModal" :removeWatchList="removeWatchList"
        :userId="selectedWatchListuserID" :watchId="selectedWatchListID" @removeTrigered="handleRemoveWatchList" />

      <RemoveSymbolModal v-on:close="toggleModal" :removeSymbol="removeSymbol" @removeSymbol="handleRemoveSymbol"
        v-on:removeWatchList="handleRemoveWatchList" :watchId="selectedWatchListID" />
      <!--       
    <tailwind-modal
    :show-modal="openDeleteColumnModal"
    v-on:change="toggleModal"
    :showHeader="false"
  >
    

  <div
      class="min-w-[34rem] px-10 py-4"
      style="
        border: 0.3px solid rgba(2, 189, 45, 0.3);
        box-sizing: border-box;
        scrollbar-color: red;
        border-radius: 3px;
        background-color: #151515;
      "
    >
    <div class="h-[3vh] space-y-4">
      <span class="text-lg">Are you sure you want to delete the selected column?</span>
    </div>
      <span v-if="modalErrorMessage" class="text-md text-[#FF0A00]">{{ modalErrorMessage }}</span>
      <span v-if="modalSuccessMessage" class="text-md text-[#02BD2D]">{{ modalSuccessMessage }}</span>

      <div class="buttons flex justify-end mt-12">
        <button  class="gray-button px-4 rounded mr-4 font-semibold py-1 text-sm"
        @click="closeDeleteColumnModal"
        >
          Close
        </button>

        <button class="red-button px-4 font-semibold py-1 text-sm" @click="handleDeleteColumn">
          Delete
        </button>
      </div>
    </div>
  </tailwind-modal>   -->


      <tailwind-modal :show-modal="openEditColumnModal" v-on:change="toggleModal" :showHeader="false">


        <div class="min-w-[30rem] h-[60vh] overflow-y-auto"
          style="border: 0.3px solid rgba(2, 189, 45, 0.1);box-sizing: border-box;scrollbar-color: red;border-radius: 3px;background-color: #151515;">
          <div class="w-full h-full bg-black p-4 relative">
            <div class="w-full h-fit flex justify-end"
              :class="showWarningPreset ? 'pointer-events-none opacity-40' : ''">
              <span :class="modalLoading ? 'pointer-events-none cursor-default opacity-50' : ''" title="close"
                class="w-fit p-2 rounded-full hover:bg-white/10 cursor-pointer" @click="closeEditColumnModal">
                <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M0.226779 0.226779C0.27903 0.174395 0.341103 0.132834 0.409441 0.104477C0.477779 0.0761196 0.551041 0.0615234 0.625029 0.0615234C0.699017 0.0615234 0.772278 0.0761196 0.840617 0.104477C0.908955 0.132834 0.971027 0.174395 1.02328 0.226779L4.00003 3.20465L6.97678 0.226779C7.02908 0.17448 7.09117 0.132994 7.1595 0.10469C7.22783 0.0763859 7.30107 0.0618179 7.37503 0.0618179C7.44899 0.0618179 7.52223 0.0763859 7.59056 0.10469C7.65889 0.132994 7.72098 0.17448 7.77328 0.226779C7.82558 0.279078 7.86706 0.341165 7.89537 0.409497C7.92367 0.477829 7.93824 0.551067 7.93824 0.625029C7.93824 0.698991 7.92367 0.772229 7.89537 0.84056C7.86706 0.908892 7.82558 0.97098 7.77328 1.02328L4.7954 4.00003L7.77328 6.97678C7.82558 7.02908 7.86706 7.09117 7.89537 7.1595C7.92367 7.22783 7.93824 7.30107 7.93824 7.37503C7.93824 7.44899 7.92367 7.52223 7.89537 7.59056C7.86706 7.65889 7.82558 7.72098 7.77328 7.77328C7.72098 7.82558 7.65889 7.86706 7.59056 7.89537C7.52223 7.92367 7.44899 7.93824 7.37503 7.93824C7.30107 7.93824 7.22783 7.92367 7.1595 7.89537C7.09117 7.86706 7.02908 7.82558 6.97678 7.77328L4.00003 4.7954L1.02328 7.77328C0.97098 7.82558 0.908892 7.86706 0.84056 7.89537C0.772228 7.92367 0.698991 7.93824 0.625029 7.93824C0.551067 7.93824 0.477829 7.92367 0.409497 7.89537C0.341165 7.86706 0.279078 7.82558 0.226779 7.77328C0.17448 7.72098 0.132994 7.65889 0.10469 7.59056C0.0763859 7.52223 0.0618179 7.44899 0.0618179 7.37503C0.0618179 7.30107 0.0763859 7.22783 0.10469 7.1595C0.132994 7.09117 0.17448 7.02908 0.226779 6.97678L3.20465 4.00003L0.226779 1.02328C0.174395 0.971028 0.132834 0.908955 0.104477 0.840617C0.0761196 0.772278 0.0615234 0.699017 0.0615234 0.625029C0.0615234 0.551041 0.0761196 0.477779 0.104477 0.409441C0.132834 0.341103 0.174395 0.27903 0.226779 0.226779Z"
                    fill="#758278" />
                </svg>
              </span>
            </div>
            <div class="space-y-4">
              <div class="text-white space-x-4">
                <span class="text-[18px]">Edit Set Columns</span>
                <span v-if="modalErrorMessage" class="text-[16px] text-[#FF0A00]">{{ modalErrorMessage }}</span>
                <span v-if="modalSuccessMessage" class="text-[16px] text-[#02BD2D]">{{ modalSuccessMessage }}</span>
              </div>
              <div class="w-full"
                :class="modalLoading || showWarningPreset ? 'pointer-events-none cursor-default opacity-70' : ''">
                <input type="text" v-model="selectedColumnListName"
                  class="bg-[#1E2224] w-full rounded-[3px] hover:border-[1px] hover:border-[#4d5052] h-[5vh] text-white/30 focus:outline-none px-4 placeholder:font-bold placeholder:text-[#4d5052]"
                  placeholder="Name you set columns" />
              </div>
              <div :class="modalLoading || showWarningPreset ? 'pointer-events-none cursor-default opacity-70' : ''"
                class="w-full h-[37vh] rounded-[3px] px-2 py-4 bg-[#1E2224]">
                <div class="w-full h-[100%] overflow-y-auto">
                  <ul @click.stop class="dropdown-menu w-full h-full border-none">
                    <div class="w-full flex items-center space-x-2 px-2 hover:bg-white/10">
                      <label class="container">
                        <input id="allcolumn" :checked="isAllEditColumn" @click="handleAllEditColumn()" type="checkbox"
                          class="checkbox-round" />
                        <span class="checkmark"></span>
                      </label>


                      <label for="allcolumn">
                        <li
                          class="text-sm text-white/70 py-2 px-4 cursor-pointer font-normal block w-full whitespace-nowrap bg-transparent">
                          All Columns ({{ WatchConfig?.length }})
                        </li>
                      </label>

                    </div>

                    <div class="w-full flex items-center space-x-2 px-2 hover:bg-white/10"
                      v-for="(config, i) in WatchConfig" :key="i">
                      <label class="container">
                        <input :disabled="config.label == 'Symbol' ? true : false" :id="config.label" :checked="selectedColumnListData
                          ? selectedColumnListData?.find(
                            (val) => val.label == config.label
                          )
                            ? true
                            : false
                          : ''
                          " type="checkbox" class="checkbox-round" @click="handleEditColumnValues(config)" />
                        <span class="checkmark"></span>
                      </label>


                      <label :for="config.label">
                        <li :class="selectedColumnListData
                          ? selectedColumnListData?.find(
                            (val) => val.label == config.label
                          )
                            ? 'text-white font-semibold'
                            : 'text-white/70' : 'text-white/70'"
                          class="text-sm py-2 px-4 cursor-pointer font-normal block w-full whitespace-nowrap bg-transparent">
                          {{ config.label }}
                        </li>
                      </label>

                    </div>
                  </ul>

                </div>
              </div>
              <div class="w-full flex items-center justify-between">
                <div @click="openWarningPresent"
                  :class="modalLoading || showWarningPreset ? 'pointer-events-none cursor-default opacity-50' : ''"
                  title="delete"
                  class="border hover:opacity-70 border-[#FF0A00] bg-red-600 relative cursor-pointer rounded-[3px] px-4 py-2 deleteButton">
                  <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M2.25 13.75C1.8375 13.75 1.48425 13.603 1.19025 13.309C0.89625 13.015 0.7495 12.662 0.75 12.25V2.5H0V1H3.75V0.25H8.25V1H12V2.5H11.25V12.25C11.25 12.6625 11.103 13.0157 10.809 13.3097C10.515 13.6038 10.162 13.7505 9.75 13.75H2.25ZM9.75 2.5H2.25V12.25H9.75V2.5ZM3.75 10.75H5.25V4H3.75V10.75ZM6.75 10.75H8.25V4H6.75V10.75Z"
                      fill="#FF0A00" />
                  </svg>
                </div>
                <div class="flex items-center space-x-6">
                  <div title="close"
                    :class="modalLoading || showWarningPreset ? 'pointer-events-none cursor-default opacity-50' : ''"
                    @click="closeEditColumnModal" class="cursor-pointer gray-button px-8 py-1 text-[14px]">
                    Cancel
                  </div>
                  <div @click="saveEditColumnModal" title="save"
                    :class="modalLoading || showWarningPreset ? 'pointer-events-none cursor-default opacity-50' : ''"
                    class="bg-[#02bd2d] hover:opacity-70 cursor-pointer rounded-[3px] px-12 py-1 text-md">
                    Save
                  </div>
                </div>
              </div>
              <div v-if="showWarningPreset" class="bg-[#181818] absolute bottom-16 w-[60%] p-4 space-y-2 text-[15px]">
                <div class="text-[#F4CC01]">
                  Are you sure you want to delete the whole preset ?
                </div>
                <div class="w-full flex items-center justify-end space-x-4 text-sm">
                  <div class="border border-white px-4 cursor-pointer rounded-[4px] hover:opacity-70"
                    @click="closeWarningPreset">No</div>
                  <div class="bg-[#02bd2d] px-4 rounded-[4px] cursor-pointer hover:opacity-70"
                    @click="handleDeleteColumn">Yes</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </tailwind-modal>




    </div>

    <!-- Marque + UserImg -->
    <div class="flex-col px-4">

      <div class="text-[12px] text-white flex pt-6 pb-2 items-center justify-between">

        <div class="flex space-x-3 items-center">
          <div>
            <h1 style="
              font-family: 'Inter';
              font-style: normal;
              font-weight: 700;
              font-size: 1.4vw;
              line-height: 36px;
              display: flex;
              align-items: center;
              color: #f8f9f9;
            " class="Inter-Bold">
              Watchlists
            </h1>
          </div>
          <div>|</div>

          <div class="text-white whitespace-nowrap flex space-x-2 tracking-wide items-center"
            v-if="selectedWatchListuserName.length > 0">
            <!-- <div class="text-[#E5E9EC] text-[15px]" :title="selectedWatchListuserName">{{ processString(selectedWatchListuserName,3) }}</div> -->
            <div class="text-[#E5E9EC] text-[15px]" :title="selectedWatchListuserName">{{ selectedWatchListuserName }}
            </div>
            <span>-</span>
            <div class="opacity-40 text-[12px]">{{ selectedWatchItemCount }} Items</div>
            <span class="opacity-40" v-if="updatedAt">-</span>
            <span class="opacity-40 text-[12px]"> {{ updatedAt ? `Updated: ${updatedAt}` : '' }}</span>
            <span class="opacity-40">-</span>
            <span class="opacity-40 text-[12px]">Created: {{ createdAt }}</span>
            <span class="px-2">|</span>
            <span class="text-[#E5E9EC] text-[12px]">SET COLUMNS</span>
            <div class="dropdown relative justify-end">
              <button
                class="dropdown-toggle flex items-center bg-[#1E2224] border border-[#313735] relative cursor-pointer rounded-[3px] justify-between w-48 py-1 px-2 relative transition duration-150 ease-in-out flex items-center whitespace-nowrap"
                type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                <span class="text-white/40 text-[12px]" :title="selectedColumnList?.name">{{ (selectedColumnList?.name
                  &&
                  (processString(selectedColumnList?.name, 15) + " (" + selectedColumnList?.columnList?.length + ")"))
                  ||
                  'Select Set Columns'}}</span>
                <span>
                  <svg width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.5 1.375L3.5 4.375L6.5 1.375" stroke="#565a5c" stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </span>
              </button>

              <ul @click.stop :class="userSetColumnList?.length ? 'max-h-48' : 'h-40 cursor-default'"
                class="dropdown-menu w-60 pr-1 bg-[#1e2224] scrollbar overflow-y-auto overflow-x-hidden absolute text-base z-30 float-left list-none shadow-lg hidden m-0 !top-2 bg-clip-padding border-none mt-2">
                <div v-if="userSetColumnList?.length">
                  <li v-for="(item, i) in userSetColumnList" :key="i" @mouseover="handleCurrentHoverColumn(item?._id)"
                    :class="selectedColumnList?.name == item?.name ? 'text-white' : 'text-white/70'"
                    class="p-1 cursor-pointer text-[13px] hover:text-white/80 pl-2 w-full flex items-center justify-between my-1">
                    <div @click="handleSetColumnSelect(item)" class="w-8/12">
                      <span :title="item?.name">{{ processString(item?.name, 15) }}
                        {{ `(${item?.columnList?.length})` }}</span>
                    </div>
                    <div v-if="currentHoverId == item?._id" class="flex items-center space-x-2 w-4/12 justify-end">
                      <div title="edit" @click="handleEditColumn(item)">
                        <span class="text-[#02BD2D] text-[11px]">EDIT</span>
                      </div>

                    </div>

                  </li>
                </div>
                <div v-else class="w-full h-full items-center justify-center flex">
                  <span class="text-white/40 text-[12px]">No Set Columns</span>
                </div>
              </ul>
            </div>




            <div class="cursor-pointer hover:opacity-70 px-2" title="Add new column" @click="handleOpenSaveModal">
              <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M15.0625 0.625H1.9375C1.5894 0.625 1.25556 0.763281 1.00942 1.00942C0.763281 1.25556 0.625 1.5894 0.625 1.9375V15.0625C0.625 15.4106 0.763281 15.7444 1.00942 15.9906C1.25556 16.2367 1.5894 16.375 1.9375 16.375H15.0625C15.4106 16.375 15.7444 16.2367 15.9906 15.9906C16.2367 15.7444 16.375 15.4106 16.375 15.0625V1.9375C16.375 1.5894 16.2367 1.25556 15.9906 1.00942C15.7444 0.763281 15.4106 0.625 15.0625 0.625ZM13.0938 9.15625H9.15625V13.0938C9.15625 13.2678 9.08711 13.4347 8.96404 13.5578C8.84097 13.6809 8.67405 13.75 8.5 13.75C8.32595 13.75 8.15903 13.6809 8.03596 13.5578C7.91289 13.4347 7.84375 13.2678 7.84375 13.0938V9.15625H3.90625C3.7322 9.15625 3.56528 9.08711 3.44221 8.96404C3.31914 8.84097 3.25 8.67405 3.25 8.5C3.25 8.32595 3.31914 8.15903 3.44221 8.03596C3.56528 7.91289 3.7322 7.84375 3.90625 7.84375H7.84375V3.90625C7.84375 3.7322 7.91289 3.56528 8.03596 3.44221C8.15903 3.31914 8.32595 3.25 8.5 3.25C8.67405 3.25 8.84097 3.31914 8.96404 3.44221C9.08711 3.56528 9.15625 3.7322 9.15625 3.90625V7.84375H13.0938C13.2678 7.84375 13.4347 7.91289 13.5578 8.03596C13.6809 8.15903 13.75 8.32595 13.75 8.5C13.75 8.67405 13.6809 8.84097 13.5578 8.96404C13.4347 9.08711 13.2678 9.15625 13.0938 9.15625Z"
                  fill="#737476" />
              </svg>
            </div>
            <div>|</div>

          </div>
        </div>







        <div class="flex  gap-4 items-center" :class="watchListTableData.length == 0 ? 'w-full justify-end flex' : ''">

          <!-- <div @click="generatePDF()" title="Download company report" :class="watchListApi_Data.length > 0 && signedInUser.role=='premium' && isFooter==true && showChartLoader==false ? 'cursor-pointer text-[#E5E9EC]':'cursor-default pointer-events-none opacity-20'" class="flex space-x-2 hover:opacity-80 text-[13px] whitespace-nowrap">
            <span>
              <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 9.5V12.1667C14 12.5203 13.8478 12.8594 13.5769 13.1095C13.306 13.3595 12.9386 13.5 12.5556 13.5H2.44444C2.06135 13.5 1.69395 13.3595 1.42307 13.1095C1.15218 12.8594 1 12.5203 1 12.1667V9.5M3.88889 6.16667L7.5 9.5M7.5 9.5L11.1111 6.16667M7.5 9.5V1.5" stroke="#F8F9F9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </span>
            <span>COMPANY REPORT</span>
          </div> -->
          <div class="dropdown relative justify-end">
            <button title="New menu item"
              class="dropdown-toggle text-[14px] hover:opacity-80 bg-[#02BD2D] text-white relative cursor-pointer rounded-[3px] actionBtn transition duration-150 ease-in-out flex items-center whitespace-nowrap"
              type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
              Actions
            </button>
            <ul @click.stop
              class="dropdown-menu w-[10vw] scrollbar overflow-y-auto overflow-x-hidden h-34 absolute bg-[#e5e9ec] text-base z-30 float-left list-none text-center shadow-lg hidden p-2 !top-2 bg-clip-padding border-none">
              <li :class="userSelectedTableRecords.length == 0 ? 'cursor-pointer' : 'cursor-default'">
                <button
                  :class="userSelectedTableRecords.length == 0 ? 'text-[#2E3238] hover:bg-[#02BD2D] hover:text-white' : 'text-[#BCC0C3] pointer-events-none'"
                  @click="handleCreateWacthListModelState"
                  class="text-[12px] tracking-wide font-semibold rounded-[3px] w-full py-1">
                  CREATE WATCHLIST
                </button>
              </li>
              <li :class="watchListApi_Data.length > 0 ? 'cursor-pointer' : 'cursor-default'">
                <button
                  :class="watchListApi_Data.length > 0 ? 'text-[#2E3238] hover:bg-[#02BD2D] hover:text-white' : 'text-[#BCC0C3] pointer-events-none'"
                  @click="handleAddSymbolModelState"
                  class="text-[12px] tracking-wide font-semibold w-full rounded-[3px] py-1">
                  ADD SYMBOLS
                </button>
              </li>
              <li
                :class="userSelectedTableRecords.length > 0 && watchListApi_Data.length > 1 ? 'cursor-pointer' : 'cursor-default'">
                <button
                  :class="userSelectedTableRecords.length > 0 && watchListApi_Data.length > 1 ? 'text-[#2E3238] hover:bg-[#02BD2D] hover:text-white ' : 'text-[#BCC0C3] pointer-events-none'"
                  @click="handleCopyWatchlist()"
                  class="text-[12px] tracking-wide font-semibold w-full rounded-[3px] py-1">
                  COPY TO
                </button>
              </li>
              <li
                :class="userSelectedTableRecords.length > 0 && watchListApi_Data.length > 1 ? 'cursor-pointer' : 'cursor-default'">
                <button
                  :class="userSelectedTableRecords.length > 0 && watchListApi_Data.length > 1 ? 'text-[#2E3238] hover:bg-[#02BD2D] hover:text-white ' : 'text-[#BCC0C3] pointer-events-none'"
                  @click="handleMoveWatchlist()"
                  class="text-[12px] tracking-wide font-semibold w-full rounded-[3px] py-1">
                  MOVE TO
                </button>
              </li>
              <li
                :class="(watchListApi_Data?.length > 0 && signedInUser.role == 'premium' && isFooter == true && showChartLoader == false) ? 'cursor-pointer' : 'cursor-default'">
                <button
                  :class="(watchListApi_Data?.length > 0 && signedInUser.role == 'premium' && isFooter == true && showChartLoader == false) ? 'text-[#2E3238] hover:bg-[#02BD2D] hover:text-white ' : 'text-[#BCC0C3] pointer-events-none'"
                  class="text-[12px] tracking-wide font-semibold rounded-[3px] w-full py-1" @click="generatePDF()">
                  DOWNLOAD REPORT
                </button>
              </li>
            </ul>
          </div>




          <div class="dropdown relative justify-end">
            <button title="Delete menu item"
              class="dropdown-toggle hover:opacity-80 text-white border border-[#FF0A00] relative cursor-pointer rounded-[3px] px-4 py-[6px] deleteButton transition duration-150 ease-in-out flex items-center whitespace-nowrap"
              type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
              <svg width="10" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M2.25 13.75C1.8375 13.75 1.48425 13.603 1.19025 13.309C0.89625 13.015 0.7495 12.662 0.75 12.25V2.5H0V1H3.75V0.25H8.25V1H12V2.5H11.25V12.25C11.25 12.6625 11.103 13.0157 10.809 13.3097C10.515 13.6038 10.162 13.7505 9.75 13.75H2.25ZM9.75 2.5H2.25V12.25H9.75V2.5ZM3.75 10.75H5.25V4H3.75V10.75ZM6.75 10.75H8.25V4H6.75V10.75Z"
                  fill="#FF0A00" />
              </svg>
            </button>
            <ul @click.stop
              class="dropdown-menu w-[10vw] scrollbar overflow-y-auto overflow-x-hidden h-34 absolute bg-[#e5e9ec] text-base z-30 float-left list-none text-center shadow-lg hidden p-2 m-0 !top-2 bg-clip-padding border-none mt-2">
              <li :class="watchListApi_Data.length > 0 ? 'cursor-pointer' : 'cursor-default'">
                <button
                  :class="watchListApi_Data.length > 0 ? 'text-[#2E3238] hover:border hover:text-[#FF0A00] hover:border-[#FF0A00] ' : 'text-[#BCC0C3] pointer-events-none'"
                  @click="handleRemoveWatchListModalState"
                  class="text-[12px] tracking-wide border font-semibold rounded-[3px] w-full py-1">
                  REMOVE WATCHLIST
                </button>
              </li>
              <li :class="userSelectedTableRecords.length > 0 ? 'cursor-pointer' : 'cursor-default'">
                <button
                  :class="userSelectedTableRecords.length > 0 ? 'text-[#2E3238] hover:border hover:text-[#FF0A00] hover:border-[#FF0A00] ' : 'text-[#BCC0C3] pointer-events-none'"
                  @click="handleRemoveSymbolModalState"
                  class="text-[12px] tracking-wide border font-semibold w-full rounded-[3px] py-1">
                  REMOVE SYMBOLS
                </button>
              </li>







            </ul>


          </div>

        </div>
      </div>

      <div class="flex w-full mt-2 space-x-4 items-center">
        <div>
          <span class="text-[#f8f9f9] text-[12px] font-bold">MY WATCHLISTS</span>
        </div>

        <!-- watchlist lists   -->

        <div class="xl:w-[88%] w-9/12">
          <vue-horizontal class="horizontal px-3" :displacement="0.3">
            <button v-for="(value, i) in watchListApi_Data" :key="value._id" class="min-w-[11%] max-w-fit py-1"
              :class="[selectedButtonClass(value._id), { 'watchlist-button': value._id != selectedWatchListID }]"
              @click="handleUserSelectedWatchList(value, i)">
              <!-- User Watchlist 1 (12) -->
              {{ value.name }} ({{ value.symbolList.length }})
            </button>
          </vue-horizontal>
        </div>

      </div>



      <!-- ///////////////------------------TABLE------------------------------------////////////////////////////////////////// -->
      <div :class="isFooter ? 'h-[55vh]' : 'h-[80vh]'"
        class="flex flex-col  w-full box-border  mt-4  marketwatchTableHeight">
        <div class=" w-full flex h-full">
          <div class="  w-full h-full">
            <div class="w-full overflow-x-auto">
              <!-- h-[10vh] table prev height-->

              <div v-if="isLoading" class="w-full p-2 h-full overflow-auto overflow-x-hidden">
                <SkeletonTableLoader :rows="16" screen="large" />
              </div>

              <div v-if="watchListTableData.length == 0 && isLoading == false" class="h-[50vh] mt-auto flex">
                <AlertShort alertType="Warning" class="m-auto">
                  <span class="text-[#F4CC01]">
                    You have no watchlists. <span class="hover:underline font-semibold cursor-pointer"
                      title="Create watchlist" @click="handleCreateWacthListModelState">Create watchlist</span>
                  </span>
                </AlertShort>
              </div>

              <div
                v-if="watchListTableData.length > 0 && watchListTableData[0].symbolList.length == 0 && isLoading == false"
                class="h-[50vh]  flex items-center justify-center">
                <AlertShort alertType="Warning" class="m-auto">
                  <span class="text-[#F4CC01]">
                    You have no symbols. <span class="hover:underline font-semibold cursor-pointer" title="Add symbols"
                      @click="handleAddSymbolModelState">Add Symbol</span>
                  </span>
                </AlertShort>
              </div>

              <div v-if="watchListTableData.length && watchListTableData[0].symbolList.length"
                :class="isFooter ? 'h-[55vh]' : 'h-[80vh]'"
                class="flex rounded-lg border-[0.3px] overflow-y-auto overflow-x-auto border-[#7582785c]">
                <table v-if="watchListTableData.length > 0" class="w-full  overflow-x-auto h-fit  rounded-md"
                  :class="[!watchListTableData.length ? 'h-full' : '']">
                  <thead class="border-b-[#7582785c] sticky top-0 z-[1] rounded-md ">
                    <tr class="border-b-[0.3px] border-[#7582785c] bg-[#1E2224] rounded-md">
                      <!-- <th class="text-left text-[9px] font-medium text-white  py-2 border-[#7582785c] ">
                      
                    </th> -->
                      <th scope="col"
                        class="md:text-xs w-[2%] rounded-l-md  rounded-r-md  px-6 text-left font-medium text-white bg-[#1E2224] sticky left-[-.1vw] py-2 border-[#7582785c] pl-3">
                        <label class="container">
                          <input id="checkbox-all-search" type="checkbox" class="checkbox-round"
                            @click="handleSelectAllTableRecords()" :checked="checkAllRecordsFlag" />
                          <span class="checkmark"></span>
                        </label>

                        <!-- <span class="px-1 pr-3 items-left text-sm float-left">
                        <input id="checkbox-all-search" type="checkbox" @click="handleSelectAllTableRecords"
                        :checked="checkAllRecordsFlag"
                        class="text-blue-600 bg-gray-100 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 h-2.5 w-2.5" /></span> -->
                      </th>
                      <th scope="col"
                        class="md:text-xs w-[2%] text-left bg-[#1E2224] px-1 font-medium text-white py-2 sticky chartLeft border-[#7582785c]">
                        <p class="text-[.7vw] font-bold w-[100%] text-left inline">
                          Chart
                          <!-- <svg xmlns="http://www.w3.org/2000/svg" class="w-[1vw] h-[1.2vh] inline" fill="none"
                            viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" style="transform: rotate(90deg)">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 8l4 4-4 4M6 16l-4-4 4-4" />
                          </svg> -->
                        </p>
                      </th>
                      <th v-for="(config, i) in newTableColumn" :key="i" scope="col"
                        :class="config.colName == 'symbol' ? 'sticky symbolLeft bg-[#1E2224]' : ''"
                        class="md:text-xs text-left font-medium whitespace-nowrap text-white w-20 px-3  py-2 border-[#7582785c]">
                        <div :title="getTableDef(config)" @click="handleSorting(config?.colName)" class="w-[100%]">
                          <div class="flex w-full items-center space-x-2">
                            <p class="text-[.7vw] font-bold text-left cursor-pointer">
                              <template v-if="config.label == 'Confidance Indicator'">
                                CI
                              </template>
                              <tamplate v-else-if="config.label === 'Fundamental Score'">
                                FS%
                              </tamplate>
                              <tamplate v-else-if="config.label === 'Technical Score'">
                                TS%
                              </tamplate>
                              <template v-else>
                                {{ config.label }}
                              </template>
                            </p>
                            <span class="rounded-full hover:bg-[#414242] cursor-pointer">
                              <svg v-if="sortOrder?.order == 'asc' && sortOrder.name == config?.colName"
                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor" class="w-4 text-gray-500 h-4">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                              </svg>

                              <svg v-if="sortOrder?.order == 'desc' && sortOrder.name == config?.colName"
                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor" class="w-4 text-gray-500 h-4">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                              </svg>
                            </span>
                          </div>
                        </div>
                      </th>
                      <th colspan="2"
                        class="sticky right-0 md:text-xs w-[5%]   px-6 text-left font-medium text-white bg-[#1E2224] left-[-.1vw] py-2 border-[#7582785c] pl-3">
                        <div v-if="watchListApi_Data.length > 0" class="dropdown relative justify-end">
                          <button
                            class="dropdown-toggle text-white transition duration-150 ease-in-out flex items-center whitespace-nowrap"
                            type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                            <svg class="setting-column w-[.9vw]" xmlns="http://www.w3.org/2000/svg" fill="none"
                              viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                              <path stroke-linecap="round" stroke-linejoin="round"
                                d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z" />
                              <path stroke-linecap="round" stroke-linejoin="round"
                                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                            </svg>
                          </button>


                          <ul @click.stop
                            class="dropdown-menu w-[12vw] scrollbar overflow-y-auto overflow-x-hidden h-[50vh] absolute bg-[#e5e9ec] text-base z-50 float-left list-none text-center shadow-lg mt-1 hidden m-0 bg-clip-padding border-none">

                            <!-- <draggable
                          :list="WatchConfig"
                          :disabled="!enabled"
                          class="list-group"
                          ghost-class="ghost"
                          :move="checkMove"
                          @start="dragging = true"
                          @end="dragging = false"
                        > -->
                            <div class="w-full flex items-center px-2 hover:bg-[#dbdee0]">
                              <label class="container">
                                <input id="allcolumn" :checked="isAllColumn" @click="handleAllColumn()" type="checkbox"
                                  class="checkbox-round" />
                                <span class="checkmark_column"></span>
                              </label>


                              <label for="allcolumn">
                                <li :class="true ? 'Inter-Bold text-[#2E3238]' : 'text-[#2e3238]'"
                                  class="text-sm py-2 px-4 cursor-pointer font-normal block w-full whitespace-nowrap bg-transparent">
                                  All Columns ({{ WatchConfig?.length }})
                                </li>
                              </label>

                            </div>

                            <div class="w-full flex items-center px-2 hover:bg-[#dbdee0]"
                              v-for="(config, i) in WatchConfig" :key="i">
                              <label class="container">
                                <input :disabled="config.label == 'Symbol' ? true : false" :id="config.label" :checked="newTableColumn
                                  ? newTableColumn.find(
                                    (val) => val.label == config.label
                                  )
                                    ? true
                                    : false
                                  : ''
                                  " type="checkbox" class="checkbox-round" @click="handleColumn(config)" />
                                <span class="checkmark_column"></span>
                              </label>


                              <label :for="config.label">
                                <li :class="newTableColumn
                                  ? newTableColumn.find(
                                    (val) => val.label == config.label
                                  )
                                    ? 'text-[#2E3238] Inter-Bold'
                                    : 'text-[#2e3238]' : 'text-[#2e3238]'"
                                  class="text-sm py-2 px-4 cursor-pointer font-normal block w-full whitespace-nowrap bg-transparent">
                                  {{ config.label }}
                                </li>
                              </label>

                            </div>



                            <!-- </draggable> -->
                          </ul>


                        </div>
                      </th>

                    </tr>
                  </thead>

                  <tbody class="">



                    <tr style="" class="border-b-[0.3px] border-[#7582785c] hover:bg-[#2E2E2F] h-[3vh]"
                      v-for="(item, i) in watchlistTableDataList" :key="item.name"
                      @mouseover="handleTableMouseItem(item)" @mouseleave="handleTableMouseItemLeave"
                      :class="keyIndex == item.name ? 'bg-[#2E2E2F]' : ''">
                      <!-- class="  w-[12%] py-2 whitespace-nowrap text-sm font-medium text-[#758278] border-[#7582785c]" -->
                      <td
                        class="pl-3 md:text-xs sticky bg-[#151515] left-[-.1vw] text-left font-medium text-white w-[3%] border-[#7582785c] cursor-pointer">
                        <!-- <span class="flex">
                        <span class="p-1 pr-3">
                          <input id="checkbox-all-search" type="checkbox" @change="handleSelectedTableRecord(item)"
                            :checked="checkAllRecordsFlag" class="w-2.5 h-2.5 rounded text-sm"
                            style="background-color: #999999" /></span>
                      </span> -->

                        <label class="container mb-1">
                          <input id="checkbox-all-search" type="checkbox" class="checkbox-round"
                            @change="handleSelectedTableRecord(item, i)" :checked="checkAllRecordsFlag" />
                          <span class="checkmark"></span>
                        </label>
                      </td>

                      <td
                        class="md:text-xs w-fit sticky chartLeft text-center font-medium pl-2.5 bg-[#151515] text-white border-[#7582785c]">
                        <p class="text-[.8vw] cusror-pointer w-fit rounded-[6px] text-center">
                          <router-link :to="`/chart?t=${item.name}`">
                            <svg width="22" height="15" viewBox="0 0 22 15" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M21.5564 3.32435C21.5564 1.4904 20.0676 0 18.2349 0H3.3215C1.48877 0 0 1.4904 0 3.32435V11.6807C0 13.5147 1.48877 15 3.3215 15H18.2349C20.0676 15 21.5564 13.5147 21.5564 11.6807V3.32435ZM19.5183 3.32435C19.5183 2.61704 18.9433 2.04109 18.2349 2.04109H3.3215C2.61305 2.04109 2.03808 2.61704 2.03808 3.32435V11.6807C2.03808 12.388 2.61305 12.964 3.3215 12.964H18.2349C18.9433 12.964 19.5183 12.388 19.5183 11.6807V3.32435Z"
                                fill="#02BD2D" />
                              <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M4.50729 12.0242L7.6029 8.79074L9.10194 10.0083C9.50237 10.3367 10.0825 10.3115 10.4572 9.95275L13.979 6.55261L17.2645 10.1548C17.6444 10.5742 18.2913 10.6045 18.7071 10.2256C19.1229 9.84665 19.1537 9.19997 18.7738 8.78569L14.7849 4.40543C14.595 4.19829 14.3383 4.08208 14.0611 4.07198C13.7839 4.06188 13.5169 4.16292 13.3218 4.3549L9.68718 7.85608L8.15734 6.61829C7.74665 6.27979 7.14601 6.32021 6.77638 6.70418L3.03392 10.6095C2.64376 11.0188 2.65916 11.6654 3.06472 12.0545C3.47028 12.4435 4.11713 12.4283 4.50729 12.0242Z"
                                fill="#02BD2D" />
                              <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M11.2889 3.95023L11.0527 3.71278C11.0168 3.67236 11.0014 3.61678 11.0168 3.56121C11.0322 3.51069 11.0784 3.47027 11.1297 3.46016C11.525 3.37933 12.2386 3.23787 12.5979 3.16714C12.6442 3.15703 12.6955 3.17219 12.7314 3.20755C12.7674 3.24292 12.7776 3.29344 12.7725 3.33891C12.7006 3.69762 12.5569 4.42008 12.4799 4.81416C12.4696 4.86468 12.4285 4.91015 12.3772 4.92531C12.3259 4.94046 12.2694 4.9253 12.2283 4.88994L12.0127 4.66764L9.9695 6.61779C9.78469 6.79462 9.49206 6.80472 9.29185 6.64305L7.37698 5.07182L4.36351 8.26986C4.16842 8.477 3.845 8.48711 3.63965 8.29513C3.43431 8.09809 3.42404 7.77475 3.61912 7.57266L6.95602 4.02601C7.14083 3.83403 7.44372 3.81382 7.6542 3.98054L9.5896 5.56693L11.2889 3.95023Z"
                                fill="#02BD2D" />
                            </svg>

                          </router-link>
                        </p>

                      </td>

                      <td @click="getCompanyReport(item, i)"
                        :class="`${config?.colName == 'symbol' ? 'sticky symbolLeft bg-[#151515]' : ''} ${keyIndex == item?.name ? 'pointer-events-none' : ''} ${(mouseOverSymbol == item.metadata?.symbol || item?.name == keyIndex) ? 'bg-[#2E2E2F]' : ''}`"
                        class="!md:text-[.74vw] whitespace-nowrap  text-left font-light text-[#dbdcdc] px-4  border-[#7582785c] cursor-pointer"
                        v-for="(config, i) in newTableColumn" :key="i">
                        <p :class="getWatchTableClass(item, config)"
                          :title="item?.metadata ? (config.colName == 'company_name' && item.metadata[config.colName] != '') || (config.colName == 'industry' && item.metadata[config.colName] != '') || (config.colName == 'sector' && item.metadata[config.colName] != '') ? processString(item.metadata[config.colName], 30) : '' : ''"
                          :style="{ width: config?.colName === 'exchange' ? '5vw' : '' }"
                          class="text-[.7vw] w-[100%] rounded-[6px] text-left">
                          {{ getWatchInfo(item, config) }}
                        </p>
                      </td>

                      <td>

                      </td>


                    </tr>

                  </tbody>
                </table>
              </div>

            </div>
          </div>
        </div>
      </div>


      <!-- /////////////////------------------- pdf report --------------------////////////////  -->

      <vue-html2pdf v-if="dataArray.length > 0" :show-layout="false" :float-layout="true" :enable-download="true" :preview-modal="false"
        :paginate-elements-by-height="1400" :filename="getDownloadName()" :pdf-quality="2" :manual-pagination="true"
        pdf-format="a4" :pdf-margin="10" pdf-orientation="portrait" pdf-content-width="800px"
        @progress="onProgress($event)" ref="html2Pdf">

        <!-- <div slot="pdf-content" class="bg-red-600 w-full h-full"> -->
        <div slot="pdf-content" class="px-10">
          <div class="page" style="page-break-after: always;">
            <StockReportHeader />
            <br>
            <div class="bg-[#f8f9f9] space-y-2 h-42 -mt-2 w-full flex flex-col justify-between rounded-md">
              <div class="flex space-x-2 py-1 pl-2 w-full h-[65%]">
                <div class="w-5/12 p-2 flex items-start flex-col justify-between">
                  <div class="w-full h-full">
                    <div class="flex flex-col -mt-6 justify-start items-start">
                      <span style="font-weight: 800" class="text-[44px] text-[#151515]">{{
                        companyReportDetail?.symbol || 'N/A' }}</span>
                      <span class="text-[12px] font-normal text-[#151515]">{{ companyReportDetail?.company_name
                        ||
                        'N/A' }}</span>
                    </div>
                  </div>
                  <div>
                    <span class="text-[12px] text-black font-semibold">Exchange: {{ companyReportDetail?.exchange
                      ||
                      'N/A' }}</span>
                  </div>
                </div>

                <div class="w-7/12 text-sm p-2 flex flex-col justify-between">
                  <div class="w-full">
                    <div class="flex items-center w-full space-x-2 justify-between">
                      <span class="font-light w-6/12 text-[#333333] text-[12px]">First Trade Date:</span>
                      <span class="font-semibold w-6/12 text-black text-[12px]">{{
                        companyReportDetail?.first_traded || 'N/A' }}</span>
                    </div>
                    <div class="flex items-center w-full space-x-2 justify-between">
                      <span class="font-ligh w-6/12 t text-[#333333] text-[12px]">Closing Price ($):</span>
                      <span class="font-semibold w-6/12 text-black text-[12px]">{{ companyReportDetail?.close ||
                        'N/A' }}</span>
                    </div>
                    <div class="flex items-center w-full space-x-2 justify-between">
                      <span class="font-light w-6/12 text-[#333333] text-[12px]">Annual Growth (%):</span>
                      <span class="font-semibold w-6/12 text-black text-[12px]">{{
                        companyReportDetail?.annual_growth ? companyReportDetail?.annual_growth : 'N/A'
                      }}</span>
                    </div>
                  </div>
                  <div class="w-full">
                    <div class="flex items-center w-full justify-between space-x-2"><span
                        class="font-light w-6/12 text-[#333333] text-[12px]">Business Classification
                        (GICS):</span><span class="w-6/12 font-semibold text-black text-[12px]">{{
                          companyReportDetail?.business_classification || 'N/A' }}</span></div>
                    <div class="flex w-full items-center space-x-2 justify-between whitespace-nowrap"><span
                        class="font-light text-[#333333] text-[12px] w-6/12 whitespace-nowrap">Sector:</span><span
                        class="font-semibold text-black text-[12px] w-6/12 whitespace-nowrap">{{
                          companyReportDetail?.sector || 'N/A' }}</span></div>
                    <div class="flex w-full items-center space-x-2 justify-between"><span
                        class="font-light text-[#333333] text-[12px] w-6/12">Business Classification
                        (TRBC):</span><span class="font-semibold text-black text-[12px] w-6/12">{{
                          companyReportDetail?.trbc_classification || 'N/A' }}</span></div>
                  </div>

                </div>
              </div>
              <div
                class="h-[12%] font-normal text-[#151515] text-[10px] font-semibold pb-2 text-center bg-[#EFF2F3] w-full border-t border-[#E5E9EC]">
                This Report is subject to the important notice at the bottom of the last page
              </div>

            </div>
            <br>
            <div class="w-full bg-black my-2 h-[22vh] pt-2 rounded-[10px]"
              style="border: 0.3px solid rgba(233, 233, 233, 0.2); border-radius: 3px;">
              <div class="w-full h-full bg-black apexChartdiv vld-parent" ref="areaChartHeightDiv">
                <AreaChart :is-index-chart="false" :isStockAlert="true" v-if="symbolDataList?.length > 0" class="-mt-2"
                  :chartHeight="watchListChartHeight-20" :showTrend="true" :data-chart="symbolDataList"
                  :symbol-name="selectedSymbol" :showOHLC="true" :stockAlertpdfChart="false" :pdfChart="true"
                  @value-emitted="onValueEmitted" />
              </div>
            </div>
            <br>
            <div class="w-full px-4 -mt-2">
              <div class="text-[16px] text-black font-bold -mt-2">Business Activity Summary</div>
              <div class="text-[#000000] text-justify mt-1 text-[10px]">{{ companyReportDetail?.overview ||
                'N/A' }}</div>
              <br>
              <div class="text-[16px] text-black font-bold mt-1 pb-2">Performance at a Glance</div>
              <div class="w-full space-x-0.5 flex mt-1 text-[10px] items-center">
                <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Net Profit Margin</div>
                <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">(%)</div>
                <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.net_profit ?
                  formatReportNumber(companyReportDetail?.net_profit, 'net_profit') : 'N/A' }}</div>
                <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Revenue Growth Rate 5 Yrs</div>
                <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
                <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{
                  companyReportDetail?.revenue_growth_5_yrs
                    ? formatReportNumber(companyReportDetail?.revenue_growth_5_yrs, 'revenue_growth_5_yrs') : 'N/A' }}
                </div>
              </div>
              <div class="w-full space-x-0.5 flex mt-0.5 text-[10px] items-center">
                <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Net Debt</div>
                <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">($)</div>
                <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.net_debt ?
                  formatReportNumber(companyReportDetail?.net_debt, 'net_debt') : 'N/A' }}</div>
                <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Debt Growth Rate 5 Yrs</div>
                <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
                <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.debt_growth_5_yrs
                  ?
                  formatReportNumber(companyReportDetail?.debt_growth_5_yrs, 'debt_growth_5_yrs') : 'N/A' }}</div>
              </div>
              <div class="w-full space-x-0.5 flex mt-0.5 text-[10px] items-center">
                <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Dividend % 1yr</div>
                <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">(%)</div>
                <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.div_yield_1_yr ?
                  formatReportNumber(companyReportDetail?.div_yield_1_yr, 'div_yield_1_yr') : 'N/A' }}</div>
                <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Assets to Liabilities Ratio</div>
                <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
                <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{
                  companyReportDetail?.assets_to_liabilities_ratio ?
                    formatReportNumber(companyReportDetail?.assets_to_liabilities_ratio, 'assets_to_liabilities_ratio') :
                    'N/A' }}</div>
              </div>
              <div class="w-full space-x-0.5 flex mt-0.5 text-[10px] items-center">
                <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Largest Gap Down 1 Yr</div>
                <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">(%)</div>
                <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{
                  companyReportDetail['biggest_gap_down_%']
                    ? formatReportNumber(companyReportDetail['biggest_gap_down_%'], 'biggest_gap_down_%') : 'N/A' }}</div>
                <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Total Debt to Equity LT</div>
                <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
                <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.debt_to_equity_lt
                  ?
                  formatReportNumber(companyReportDetail?.debt_to_equity_lt, 'debt_to_equity_lt') : 'N/A' }}</div>
              </div>
              <StockReportFooter :current-page="1" :total-pages="Math.ceil(dataArray.length / 3) + 2" />
            </div>
          </div>



          <div class="page" style="page-break-after: always;">
            <StockReportHeader />

            <div class="px-4">
              <div class="text-[16px] text-black font-bold -mt-1 pb-2">Fundamental Performance</div>

              <div class="w-full border-t-2 border-b-2 border-[#181818] flex justify-between">
                <div class="mb-2 w-6/12 text-[10px] font-extrabold">Earnings</div>
                <div class="mb-2 w-6/12 text-[10px] font-extrabold">Value</div>
              </div>
            </div>
            <div class="w-full space-x-0.5 px-4 flex mt-0.5 text-[10px] items-center">
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">P/E</div>
              <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">($)</div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail['p/e'] ?
                formatReportNumber(companyReportDetail['p/e']) : 'N/A' }}</div>
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Quick Ratio</div>
              <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.quick_ratio ?
                formatReportNumber(companyReportDetail?.quick_ratio, 'quick_ratio') : 'N/A' }}</div>
            </div>
            <div class="w-full space-x-0.5 px-4 flex mt-0.5 text-[10px] items-center">
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">EPS</div>
              <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">($)</div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.eps ?
                formatReportNumber(companyReportDetail?.eps) : 'N/A' }}</div>
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Current Ratio</div>
              <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.current_ratio ?
                formatReportNumber(companyReportDetail?.current_ratio, 'current_ratio') : 'N/A' }}</div>
            </div>
            <div class="w-full space-x-0.5 px-4 flex mt-0.5 text-[10px] items-center">
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Operating Margin</div>
              <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">(%)</div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.op_mgn ?
                formatReportNumber(companyReportDetail?.op_mgn, 'op_mgn') : 'N/A' }}</div>
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">ROI</div>
              <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.roi ?
                formatReportNumber(companyReportDetail?.roi, 'roi') : 'N/A' }}</div>
            </div>
            <div class="w-full space-x-0.5 px-4 flex mt-0.5 text-[10px] items-center">
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">EBITDA</div>
              <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">($)</div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.ebitda ?
                formatReportNumber(companyReportDetail?.ebitda, 'ebitda') : 'N/A' }}</div>
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">ROE</div>
              <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.roe ?
                formatReportNumber(companyReportDetail?.roe, 'roe') : 'N/A' }}</div>
            </div>
            <div class="w-full space-x-0.5 px-4 flex mt-0.5 text-[10px] items-center">
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Total Revenue</div>
              <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">($)</div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.revenue ?
                formatReportNumber(companyReportDetail?.revenue, 'revenue') : 'N/A' }}</div>
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">ROA</div>
              <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.roa ?
                formatReportNumber(companyReportDetail?.roa, 'roa') : 'N/A' }}</div>
            </div>
            <div class="w-full space-x-0.5 px-4 flex mt-0.5 text-[10px] items-center">
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Net Profit 5 Yr Av</div>
              <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">(%)</div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{
                companyReportDetail?.net_profit_5_yr_av ?
                  formatReportNumber(companyReportDetail?.net_profit_5_yr_av, 'net_profit_5_yr_av') : 'N/A' }}</div>
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Free Cash Flow</div>
              <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">($)</div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.free_cash_flow ?
                formatReportNumber(companyReportDetail?.free_cash_flow, 'free_cash_flow') : 'N/A' }}</div>
            </div>
            <div class="w-full space-x-0.5 px-4 flex mt-0.5 text-[10px] items-center">
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Net Income</div>
              <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">($)</div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.net_income ?
                formatReportNumber(companyReportDetail?.net_income, 'net_income') : 'N/A' }}</div>
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Revenue Change 1 Yr</div>
              <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{
                companyReportDetail?.revenue_change_1_yr
                  ? formatReportNumber(companyReportDetail?.revenue_change_1_yr, 'revenue_change_1_yr') : 'N/A' }}</div>
            </div>
            <div class="w-full space-x-0.5 px-4 flex mt-0.5 text-[10px] items-center">
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Pre Tax Income</div>
              <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">($)</div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.pre_tax_income ?
                formatReportNumber(companyReportDetail?.pre_tax_income) : 'N/A' }}</div>
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Revenue Change 3 Yr</div>
              <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{
                companyReportDetail?.revenue_change_3_yr
                  ? formatReportNumber(companyReportDetail?.revenue_change_3_yr, 'revenue_change_3_yr') : 'N/A' }}</div>
            </div>
            <div class="w-full space-x-0.5 px-4 flex mt-0.5 text-[10px] items-center">
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Gross Margin</div>
              <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">(%)</div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.gross_margin ?
                formatReportNumber(companyReportDetail?.gross_margin, 'gross_margin') : 'N/A' }}</div>
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Book Value per Share</div>
              <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">($)</div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.bk_val ?
                formatReportNumber(companyReportDetail?.bk_val) : 'N/A' }}</div>
            </div>
            <div class="px-4">
              <div class="w-full border-t-2 border-b-2 border-[#181818] flex justify-between">
                <div class="mb-2 w-6/12 text-black text-[10px] font-extrabold">Dividends</div>
                <div class="mb-2 w-6/12 text-black text-[10px] font-extrabold">Debt,Assets,Equity</div>
              </div>
              <div class="w-full space-x-0.5 flex mt-0.5 text-[10px] items-center">
                <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Dividend</div>
                <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">($)</div>
                <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.div_$ ?
                  formatReportNumber(companyReportDetail?.div_$) : 'N/A' }}</div>
                <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Cash per Share</div>
                <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">($)</div>
                <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.cash_per_share ?
                  formatReportNumber(companyReportDetail?.cash_per_share, 'cash_per_share') : 'N/A' }}</div>
              </div>
              <div class="w-full space-x-0.5 flex mt-0.5 text-[10px] items-center">
                <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Dividend % 1yr</div>
                <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">(%)</div>
                <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail['div_%'] ?
                  formatReportNumber(companyReportDetail['div_%'], 'div_%') : 'N/A' }}</div>
                <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Shares Outstanding</div>
                <div class="w-[5%] px-2 h-[2.5vh] bg-[#Eff2f3] text-center font-semibold"></div>
                <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.shares_out ?
                  formatReportNumber(companyReportDetail?.shares_out, 'shares_out') : 'N/A' }}</div>
              </div>
              <div class="w-full space-x-0.5 flex mt-0.5 text-[10px] items-center">
                <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Dividend Growth 3 Yrs</div>
                <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">(%)</div>
                <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.div_growth_3_yr ?
                  formatReportNumber(companyReportDetail?.div_growth_3_yr, 'div_growth_3_yr') : 'N/A' }}</div>
                <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Shares Float</div>
                <div class="w-[5%] px-2 h-[2.5vh] bg-[#Eff2f3] text-center font-semibold"></div>
                <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.shares_float ?
                  formatReportNumber(companyReportDetail?.shares_float, 'shares_float') : 'N/A' }}</div>
              </div>
              <div class="w-full space-x-0.5 flex mt-0.5 text-[10px] items-center">
                <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Dividend Average 5 Yrs</div>
                <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">($)</div>
                <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.div_avg_5_yr ?
                  formatReportNumber(companyReportDetail?.div_avg_5_yr, 'div_avg_5_yr') : 'N/A' }}</div>
                <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Debt to Equity ST</div>
                <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
                <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.debt_to_equity_st
                  ? formatReportNumber(companyReportDetail?.debt_to_equity_st, 'debt_to_equity_st') : 'N/A' }}</div>
              </div>
              <div class="w-full space-x-0.5 flex mt-0.5 text-[10px] items-center">
                <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Franking (Australia Only)</div>
                <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">(%)</div>
                <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail['frank_pct_(au)']
                  ? formatReportNumber(companyReportDetail['frank_pct_(au)'], 'frank_pct_(au)') : 'N/A' }}</div>
                <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Market Cap</div>
                <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">($)</div>
                <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.mkt_cap ?
                  formatReportNumber(companyReportDetail?.mkt_cap, 'mkt_cap') : 'N/A' }}</div>
              </div>
              <div class="w-full space-x-0.5 flex mt-0.5 text-[10px] items-center">
                <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Payout Ratio</div>
                <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">(%)</div>
                <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.payout_ratio ?
                  formatReportNumber(companyReportDetail?.payout_ratio, 'payout_ratio') : 'N/A' }}</div>
                <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Interest Coverage</div>
                <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
                <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.interest_coverage
                  ? formatReportNumber(companyReportDetail?.interest_coverage) : 'N/A' }}</div>
              </div>
            </div>
            <div v-if="(Math.ceil(dataArray.length / 3) + 2) != 2">
              <br>
              <br>
            </div>
            <div class="px-4">
              <div class="text-[16px] text-black font-bold -mt-1 pb-2">Price Performance</div>

              <div class="w-full border-t-2 border-b-2 border-[#181818] flex justify-between">
                <div class="mb-2 w-6/12 text-[10px] font-extrabold">Price Persistence</div>
                <div class="mb-2 w-6/12 text-[10px] font-extrabold">Price Volatility</div>
              </div>
            </div>
            <div class="w-full px-4 space-x-0.5 flex mt-0.5 text-[10px] items-center">
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Close > 60d MA</div>
              <div class="w-[5%] px-2 bg-[#Eff2f3] text-center font-semibold h-[2.5vh]"></div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail['close_>_60d_ma'] ?
                formatReportNumber(companyReportDetail['close_>_60d_ma'], 'close_>_60d_ma') : 'N/A' }}</div>
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">>5% Swings Count </div>
              <div class="w-[5%] px-2 bg-[#Eff2f3] h-[2.5vh] text-center font-semibold"></div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail['>5%_swings_count'] ?
                NoDecimalFormatNumber( companyReportDetail['>5%_swings_count']) : 'N/A' }}</div>
            </div>
            <div class="w-full px-4 space-x-0.5 flex mt-0.5 text-[10px] items-center">
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Close > 126d MA</div>
              <div class="w-[5%] px-2 bg-[#Eff2f3] text-center h-[2.5vh] font-semibold"></div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail['close_>_126d_ma'] ?
                formatReportNumber(companyReportDetail['close_>_126d_ma'], 'close_>_126d_ma') : 'N/A' }}</div>
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Gap Down Count</div>
              <div class="w-[5%] px-2 bg-[#Eff2f3] text-center h-[2.5vh] font-semibold"></div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.gap_down_count ?
                formatReportNumber(companyReportDetail?.gap_down_count, 'gap_down_count') : 'N/A' }}</div>
            </div>
            <div class="w-full px-4 space-x-0.5 flex mt-0.5 text-[10px] items-center">
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Close > 252d MA</div>
              <div class="w-[5%] px-2 bg-[#Eff2f3] h-[2.5vh] text-center font-semibold"></div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail['close_>_252d_ma'] ?
                formatReportNumber(companyReportDetail['close_>_252d_ma'], 'close_>_252d_ma') : 'N/A' }}</div>
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Biggest Gap Down</div>
              <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">($)</div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail['biggest_gap_down_$']
                ? formatReportNumber(companyReportDetail['biggest_gap_down_$']) : 'N/A' }}</div>
            </div>
            <div class="w-full px-4 space-x-0.5 flex mt-0.5 text-[10px] items-center">
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">60d MA > 200d MA</div>
              <div class="w-[5%] px-2 bg-[#Eff2f3] h-[2.5vh] text-center font-semibold"></div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail['60d_ma_>_200d_ma'] ?
                formatReportNumber(companyReportDetail['60d_ma_>_200d_ma'], '60d_ma_>_200d_ma') : 'N/A' }}</div>
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Biggest Gap Down</div>
              <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail['biggest_gap_down_%']
                ? formatReportNumber(companyReportDetail['biggest_gap_down_%'], 'biggest_gap_down_%') : 'N/A' }}</div>
            </div>
            <div class="w-full px-4 space-x-0.5 flex mt-0.5 text-[10px] items-center">
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">52 Week High</div>
              <div class="w-[5%] h-[2.5vh] pb-2 bg-[#Eff2f3] text-center font-semibold"></div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail['52w_high'] ?
                formatReportNumber(companyReportDetail['52w_high']) : 'N/A' }}</div>
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Cross 60d MA Count</div>
              <div class="w-[5%] h-[2.5vh] pb-2 bg-[#Eff2f3] text-center font-semibold"></div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.cross_60d_ma_count ?
                formatReportNumber(companyReportDetail?.cross_60d_ma_count, 'cross_60d_ma_count') : 'N/A' }}</div>
            </div>
            <div class="w-full px-4 space-x-0.5 flex mt-0.5 text-[10px] items-center">
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Low Since 52 Week High</div>
              <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">($)</div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{
                companyReportDetail?.low_since_52_week_high ?
                  formatReportNumber(companyReportDetail?.low_since_52_week_high) : 'N/A' }}</div>
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Highest to Lowest</div>
              <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.highest_to_lowest ?
                formatReportNumber(companyReportDetail?.highest_to_lowest) : 'N/A' }}</div>
            </div>
            <div class="px-4">
              <div class="w-full border-t-2 border-b-2 border-[#181818] flex justify-between">
                <div class="mb-2 w-6/12 text-black text-[10px] font-extrabold">Liquidity</div>
                <div class="mb-2 w-6/12 text-black text-[10px] font-extrabold">Price Growth</div>
              </div>
            </div>
            <div class="w-full px-4 space-x-0.5 flex mt-0.5 text-[10px] items-center">
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Open = Close Count</div>
              <div class="w-[5%] px-2 bg-[#Eff2f3] h-[2.5vh] text-center font-semibold"></div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail['open_=_close'] ?
                formatReportNumber(companyReportDetail['open_=_close'], 'open_=_close') : 'N/A' }}</div>
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Annual Price Growth</div>
              <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.annual_growth ?
                formatReportNumber(companyReportDetail?.annual_growth, 'annual_growth') : 'N/A' }}</div>
            </div>
            <div class="w-full px-4 space-x-0.5 flex mt-0.5 text-[10px] items-center">
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">50 Day Average Turnover</div>
              <div class="w-[5%] px-2 bg-[#Eff2f3] h-[2.5vh] text-center font-semibold"></div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{
                companyReportDetail['50_turnover_average'] ?
                  formatReportNumber(companyReportDetail['50_turnover_average'], '50_turnover_average') : 'N/A' }}</div>
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Half Year Price Growth</div>
              <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.half_year_growth ?
                formatReportNumber(companyReportDetail?.half_year_growth, 'half_year_growth') : 'N/A' }}</div>
            </div>
            <div class="w-full px-4 space-x-0.5 flex mt-0.5 text-[10px] items-center">
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">10 Day Average Turnover</div>
              <div class="w-[5%] px-2 bg-[#Eff2f3] h-[2.5vh] text-center font-semibold"></div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{
                companyReportDetail['10_turnover_average'] ?
                  formatReportNumber(companyReportDetail['10_turnover_average'], '10_turnover_average') : 'N/A' }}</div>
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Last Quarter Growth</div>
              <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail?.last_quarter_growth
                ? formatReportNumber(companyReportDetail?.last_quarter_growth, 'last_quarter_growth') : 'N/A' }}</div>
            </div>
            <div class="w-full px-4 space-x-0.5 flex mt-0.5 text-[10px] items-center">
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">50 Day Average Volume</div>
              <div class="w-[5%] px-2 bg-[#Eff2f3] h-[2.5vh] text-center font-semibold"></div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail['volume_50d_average']
                ? formatReportNumber(companyReportDetail['volume_50d_average'], 'volume_50d_average') : 'N/A' }}</div>
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">20 Day Price Growth</div>
              <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReportDetail['20_day_growth'] ?
                formatReportNumber(companyReportDetail['20_day_growth'], '20_day_growth') : 'N/A' }}</div>
            </div>
            <StockReportFooter :current-page="2" :total-pages="Math.ceil(dataArray.length / 3) + 2" />

          </div>


          <div v-for="n in Math.ceil(dataArray.length / 3)" :key="n" class="page"
            :class="n !== (Math.ceil(dataArray.length / 3)) ? 'page-break-after' : ''">
            <div class="content">
              <StockReportHeader />
              <div>
                <div class="px-4">
                  <div class="text-[16px] text-[#000000] font-black border-b-2 -mt-1 border-black pb-2">{{
                    dataArray[3 * (n - 1)]['key'] }}
                  </div>
                </div>
                <div class="text-[#000000] px-4 text-justify py-2 text-[10px]">
                  {{ dataArray[3 * (n - 1)]['value'] }}
                </div>
              </div>
              <br>
              <br>
              <div v-if="dataArray[3 * (n - 1) + 1]">
                <div class="px-4">
                  <div class="text-[16px] text-[#000000] font-black border-b-2 -mt-1 border-black pb-2">{{
                    dataArray[3 * (n - 1) + 1]['key'] }}
                  </div>
                </div>
                <div class="text-[#000000] px-4 text-justify mt-1 py-2 text-[10px]">
                  {{ dataArray[3 * (n - 1) + 1]['value'] }}
                </div>
              </div>
              <br>
              <br>
              <div v-if="dataArray[3 * (n - 1) + 2]">
                <div class="px-4">
                  <div class="text-[16px] text-[#000000] font-black border-b-2 -mt-1 border-black pb-2">{{
                    dataArray[3 * (n - 1) + 2]['key'] }}
                  </div>
                </div>
                <div class="text-[#000000] px-4 text-justify mt-1 py-2 text-[10px]">
                  {{ dataArray[3 * (n - 1) + 2]['value'] }}
                </div>
              </div>
            </div>
            <StockReportFooter :current-page="n + 2" :total-pages="Math.ceil(dataArray.length / 3) + 2" />
          </div>
        </div>
      </vue-html2pdf>

      <vue-html2pdf v-else :show-layout="false" :float-layout="true" :enable-download="true" :preview-modal="false"
        :paginate-elements-by-height="1400" :filename="getDownloadName()" :pdf-quality="2" :manual-pagination="false"
        pdf-format="a4" :pdf-margin="10" pdf-orientation="portrait" pdf-content-width="800px"
        @progress="onProgress($event)" ref="html2Pdf">

        <!-- <div slot="pdf-content" class="bg-red-600 w-full h-full"> -->
        <div slot="pdf-content" class="px-12 py-1">
          <!-- top header div  -->
          <div class="flex w-full items-center justify-between">
            <img src="../../assets/mapBlack.svg" width="210" alt="" />
            <div class="flex space-x-2 pb-5 mr-2.5 items-center">
              <span class="text-sm text-black font-semibold">Company Report</span>
              <span class="text-[12px]">produced {{ getTodayDate() }}</span>
            </div>
          </div>

          <div class="bg-[#f8f9f9] space-y-2 h-52 -mt-2 w-full flex flex-col justify-between rounded-md">
            <div class="flex space-x-2 py-1 pl-2 w-full h-[78%]">
              <div class="w-5/12 p-2 flex items-start flex-col justify-between">
                <div class="w-full h-full">
                  <div class="flex flex-col -mt-6 justify-start items-start">
                    <span style="font-weight: 800" class="text-[44px] text-[#151515]">{{ companyReport?.symbol || 'N/A'
                      }}</span>
                    <span class="text-[12px] font-normal text-[#151515]">{{ companyReport?.company_name || 'N/A'
                      }}</span>
                  </div>
                </div>
                <div>
                  <span class="text-[12px] text-black font-semibold">Exchange: {{ companyReport?.exchange || 'N/A'
                    }}</span>
                </div>
              </div>
              <div class="w-7/12 text-sm p-2 flex flex-col justify-between">
                <div class="w-full">
                  <div class="flex items-center w-full space-x-2 justify-between">
                    <span class="font-light w-6/12 text-[#333333] text-[12px]">First Trade Date:</span>
                    <span class="font-semibold w-6/12 text-black text-[12px]">{{ companyReport?.first_traded || 'N/A'
                      }}</span>
                  </div>
                  <div class="flex items-center w-full space-x-2 justify-between">
                    <span class="font-ligh w-6/12 t text-[#333333] text-[12px]">Closing Price ($):</span>
                    <span class="font-semibold w-6/12 text-black text-[12px]">{{ companyReport?.close || 'N/A' }}</span>
                  </div>
                  <div class="flex items-center w-full space-x-2 justify-between">
                    <span class="font-light w-6/12 text-[#333333] text-[12px]">Annual Growth (%):</span>
                    <span class="font-semibold w-6/12 text-black text-[12px]">{{ companyReport?.annual_growth ?
                      companyReport?.annual_growth : 'N/A' }}</span>
                  </div>
                </div>
                <div class="w-full">
                  <div class="flex items-center w-full justify-between space-x-2"><span
                      class="font-light w-6/12 text-[#333333] text-[12px]">Business Classification (GICS):</span><span
                      class="w-6/12 font-semibold text-black text-[12px]">{{ companyReport?.business_classification ||
                      'N/A'
                      }}</span></div>
                  <div class="flex w-full items-center space-x-2 justify-between whitespace-nowrap"><span
                      class="font-light text-[#333333] text-[12px] w-6/12 whitespace-nowrap">Sector:</span><span
                      class="font-semibold text-black text-[12px] w-6/12 whitespace-nowrap">{{ companyReport?.sector ||
                      'N/A'
                      }}</span></div>
                  <div class="flex w-full items-center space-x-2 justify-between"><span
                      class="font-light text-[#333333] text-[12px] w-6/12">Business Classification (TRBC):</span><span
                      class="font-semibold text-black text-[12px] w-6/12">{{ companyReport?.trbc_classification || 'N/A'
                      }}</span></div>
                </div>

              </div>
            </div>
            <div
              class="h-[12%] font-normal text-[#151515] text-[10px] font-semibold pb-2 text-center bg-[#EFF2F3] w-full border-t border-[#E5E9EC]">
              This Report is subject to the important notice at the bottom of the last page
            </div>

          </div>

          <div class="w-full bg-black my-2 h-[22vh] pt-2 rounded-[10px]"
            style="border: 0.3px solid rgba(233, 233, 233, 0.2); border-radius: 3px;">
            <div class="w-full h-full bg-black apexChartdiv vld-parent" ref="areaChartHeightDiv">
              <AreaChart :is-index-chart="false" :isStockAlert="true" v-if="symbolDataList?.length > 0" class="-mt-2"
                :chartHeight="watchListChartHeight-20" :showTrend="true" :data-chart="symbolDataList"
                :symbol-name="selectedSymbol" :showOHLC="true" :stockAlertpdfChart="false" :pdfChart="true"
                @value-emitted="onValueEmitted" />

            </div>
          </div>

          <div class="w-full px-4 -mt-2">
            <div class="text-[16px] text-black font-bold -mt-2">Business Activity Summary</div>
            <div class="text-[#000000] text-justify mt-1 text-[10px]">{{ companyReport?.business_summary || 'N/A' }}
            </div>
            <div class="text-[16px] text-black font-bold mt-1 pb-2">Performance at a Glance</div>
            <div class="w-full space-x-0.5 flex mt-1 text-[10px] items-center">
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Net Profit Margin</div>
              <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">(%)</div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReport?.net_profit ?
                formatReportNumber(companyReport?.net_profit,'net_profit') : 'N/A' }}</div>
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Revenue Growth Rate 5 Yrs</div>
              <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReport?.revenue_growth_5_yrs ?
                formatReportNumber(companyReport?.revenue_growth_5_yrs,'revenue_growth_5_yrs') : 'N/A' }}</div>
            </div>
            <div class="w-full space-x-0.5 flex mt-0.5 text-[10px] items-center">
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Net Debt</div>
              <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">($)</div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReport?.net_debt ?
                formatReportNumber(companyReport?.net_debt,'net_debt') : 'N/A' }}</div>
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Debt Growth Rate 5 Yrs</div>
              <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReport?.debt_growth_5_yrs ?
                formatReportNumber(companyReport?.debt_growth_5_yrs,'debt_growth_5_yrs') : 'N/A' }}</div>
            </div>
            <div class="w-full space-x-0.5 flex mt-0.5 text-[10px] items-center">
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Dividend % 1yr</div>
              <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">(%)</div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReport?.div_yield_1_yr ?
                formatReportNumber(companyReport?.div_yield_1_yr,'div_yield_1_yr') : 'N/A' }}</div>
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Assets to Liabilities Ratio</div>
              <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReport?.assets_to_liabilities_ratio ?
                formatReportNumber(companyReport?.assets_to_liabilities_ratio,'assets_to_liabilities_ratio') : 'N/A' }}
              </div>
            </div>
            <div class="w-full space-x-0.5 flex mt-0.5 text-[10px] items-center">
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Largest Gap Down 1 Yr</div>
              <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">(%)</div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReport['biggest_gap_down_%'] ?
                formatReportNumber(companyReport['biggest_gap_down_%'],'biggest_gap_down_%') : 'N/A' }}</div>
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Total Debt to Equity LT</div>
              <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReport?.debt_to_equity_lt ?
                formatReportNumber(companyReport?.debt_to_equity_lt,'debt_to_equity_lt') : 'N/A' }}</div>
            </div>
            <div class="text-[16px] text-black font-bold mt-1 pb-2">Fundamental Performance</div>
            <div class="w-full border-t-2 border-b-2 border-[#181818] flex justify-between">
              <div class="mb-3 w-6/12 text-[10px] font-extrabold">Earnings</div>
              <div class="mb-3 w-6/12 text-[10px] font-extrabold">Value</div>
            </div>
            <div class="w-full space-x-0.5 flex mt-0.5 text-[10px] items-center">
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">P/E</div>
              <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">($)</div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReport['p/e'] ?
                formatReportNumber(companyReport['p/e']) : 'N/A' }}</div>
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Quick Ratio</div>
              <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReport?.quick_ratio ?
                formatReportNumber(companyReport?.quick_ratio,'quick_ratio') : 'N/A' }}</div>
            </div>
            <div class="w-full space-x-0.5 flex mt-0.5 text-[10px] items-center">
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">EPS</div>
              <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">($)</div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReport?.eps ?
                formatReportNumber(companyReport?.eps) : 'N/A' }}</div>
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Current Ratio</div>
              <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReport?.current_ratio ?
                formatReportNumber(companyReport?.current_ratio,'current_ratio') : 'N/A' }}</div>
            </div>
            <div class="w-full space-x-0.5 flex mt-0.5 text-[10px] items-center">
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Operating Margin</div>
              <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">(%)</div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReport?.op_mgn ?
                formatReportNumber(companyReport?.op_mgn,'op_mgn') : 'N/A' }}</div>
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">ROI</div>
              <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReport?.roi ?
                formatReportNumber(companyReport?.roi,'roi') : 'N/A' }}</div>
            </div>
            <div class="w-full space-x-0.5 flex mt-0.5 text-[10px] items-center">
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">EBITDA</div>
              <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">($)</div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReport?.ebitda ?
                formatReportNumber(companyReport?.ebitda,'ebitda') : 'N/A' }}</div>
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">ROE</div>
              <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReport?.roe ?
                formatReportNumber(companyReport?.roe,'roe') : 'N/A' }}</div>
            </div>
            <div class="w-full space-x-0.5 flex mt-0.5 text-[10px] items-center">
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Total Revenue</div>
              <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">($)</div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReport?.revenue ?
                formatReportNumber(companyReport?.revenue,'revenue') : 'N/A' }}</div>
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">ROA</div>
              <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReport?.roa ?
                formatReportNumber(companyReport?.roa,'roa') : 'N/A' }}</div>
            </div>
            <div class="w-full space-x-0.5 flex mt-0.5 text-[10px] items-center">
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Net Profit 5 Yr Av</div>
              <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">(%)</div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReport?.net_profit_5_yr_av ?
                formatReportNumber(companyReport?.net_profit_5_yr_av,'net_profit_5_yr_av') : 'N/A' }}</div>
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Free Cash Flow</div>
              <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">($)</div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReport?.free_cash_flow ?
                formatReportNumber(companyReport?.free_cash_flow,'free_cash_flow') : 'N/A' }}</div>
            </div>
            <div class="w-full space-x-0.5 flex mt-0.5 text-[10px] items-center">
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Net Income</div>
              <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">($)</div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReport?.net_income ?
                formatReportNumber(companyReport?.net_income,'net_income') : 'N/A' }}</div>
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Revenue Change 1 Yr</div>
              <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReport?.revenue_change_1_yr ?
                formatReportNumber(companyReport?.revenue_change_1_yr,'revenue_change_1_yr') : 'N/A' }}</div>
            </div>
            <div class="w-full space-x-0.5 flex mt-0.5 text-[10px] items-center">
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Pre Tax Income</div>
              <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">($)</div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReport?.pre_tax_income ?
                formatReportNumber(companyReport?.pre_tax_income) : 'N/A' }}</div>
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Revenue Change 3 Yr</div>
              <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReport?.revenue_change_3_yr ?
                formatReportNumber(companyReport?.revenue_change_3_yr,'revenue_change_3_yr') : 'N/A' }}</div>
            </div>
            <div class="w-full space-x-0.5 flex mt-0.5 text-[10px] items-center">
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Gross Margin</div>
              <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">(%)</div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReport?.gross_margin ?
                formatReportNumber(companyReport?.gross_margin,'gross_margin') : 'N/A' }}</div>
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Book Value per Share</div>
              <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">($)</div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReport?.bk_val ?
                formatReportNumber(companyReport?.bk_val) : 'N/A' }}</div>
            </div>

          </div>
          <div class="w-full mt-4 text-[14px] font-medium text-[#000000] flex justify-end">
            1/2
          </div>



          <div class="w-full mt-2 h-full">
            <div class="flex w-full items-center justify-between">
              <img src="../../assets/mapBlack.svg" width="210" alt="" />
              <div class="flex space-x-2 pb-5 mr-2.5 items-center">
                <span class="text-sm text-black font-semibold">Company Report</span>
                <span class="text-[12px]">produced {{ getTodayDate() }}</span>
              </div>
            </div>
            <div class="px-4 -mt-2">
              <div class="w-full border-t-2 border-b-2 border-[#181818] flex justify-between">
                <div class="mb-3 w-6/12 text-black text-[10px] font-extrabold">Dividends</div>
                <div class="mb-3 w-6/12 text-black text-[10px] font-extrabold">Debt,Assets,Equity</div>
              </div>
              <div class="w-full space-x-0.5 flex mt-0.5 text-[10px] items-center">
                <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Dividend</div>
                <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">($)</div>
                <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReport?.div_$ ?
                  formatReportNumber(companyReport?.div_$) : 'N/A' }}</div>
                <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Cash per Share</div>
                <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">($)</div>
                <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReport?.cash_per_share ?
                  formatReportNumber(companyReport?.cash_per_share,'cash_per_share') : 'N/A' }}</div>
              </div>
              <div class="w-full space-x-0.5 flex mt-0.5 text-[10px] items-center">
                <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Dividend % 1yr</div>
                <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">(%)</div>
                <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReport['div_%'] ?
                  formatReportNumber(companyReport['div_%'],'div_%') : 'N/A' }}</div>
                <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Shares Outstanding</div>
                <div class="w-[5%] px-2 h-[2.5vh] bg-[#Eff2f3] text-center font-semibold"></div>
                <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReport?.shares_out ?
                  formatReportNumber(companyReport?.shares_out,'shares_out') : 'N/A' }}</div>
              </div>
              <div class="w-full space-x-0.5 flex mt-0.5 text-[10px] items-center">
                <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Dividend Growth 3 Yrs</div>
                <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">(%)</div>
                <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReport?.div_growth_3_yr ?
                  formatReportNumber(companyReport?.div_growth_3_yr,'div_growth_3_yr') : 'N/A' }}</div>
                <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Shares Float</div>
                <div class="w-[5%] px-2 h-[2.5vh] bg-[#Eff2f3] text-center font-semibold"></div>
                <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReport?.shares_float ?
                  formatReportNumber(companyReport?.shares_float,'shares_float') : 'N/A' }}</div>
              </div>
              <div class="w-full space-x-0.5 flex mt-0.5 text-[10px] items-center">
                <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Dividend Average 5 Yrs</div>
                <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">($)</div>
                <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReport?.div_avg_5_yr ?
                  formatReportNumber(companyReport?.div_avg_5_yr,'div_avg_5_yr') : 'N/A' }}</div>
                <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Debt to Equity ST</div>
                <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
                <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReport?.debt_to_equity_st ?
                  formatReportNumber(companyReport?.debt_to_equity_st,'debt_to_equity_st') : 'N/A' }}</div>
              </div>
              <div class="w-full space-x-0.5 flex mt-0.5 text-[10px] items-center">
                <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Franking (Australia Only)</div>
                <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">(%)</div>
                <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReport['frank_pct_(au)'] ?
                  formatReportNumber(companyReport['frank_pct_(au)'],'frank_pct_(au)') : 'N/A' }}</div>
                <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Market Cap</div>
                <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">($)</div>
                <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReport?.mkt_cap ?
                  formatReportNumber(companyReport?.mkt_cap,'mkt_cap') : 'N/A' }}</div>
              </div>
              <div class="w-full space-x-0.5 flex mt-0.5 text-[10px] items-center">
                <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Payout Ratio</div>
                <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">(%)</div>
                <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReport?.payout_ratio ?
                  formatReportNumber(companyReport?.payout_ratio,'payout_ratio') : 'N/A' }}</div>
                <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Interest Coverage</div>
                <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
                <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReport?.interest_coverage ?
                  formatReportNumber(companyReport?.interest_coverage) : 'N/A' }}</div>
              </div>
            </div>



            <div class="px-4">
              <div class="text-[16px] text-black font-bold -mt-1 pb-2">Price Performance</div>

              <div class="w-full border-t-2 border-b-2 border-[#181818] flex justify-between">
                <div class="mb-3 w-6/12 text-[10px] font-extrabold">Price Persistence</div>
                <div class="mb-3 w-6/12 text-[10px] font-extrabold">Price Volatility</div>
              </div>
            </div>

            <div class="w-full px-4 space-x-0.5 flex mt-0.5 text-[10px] items-center">
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Close > 60d MA</div>
              <div class="w-[5%] px-2 bg-[#Eff2f3] text-center font-semibold h-[2.5vh]"></div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReport['close_>_60d_ma'] ?
                formatReportNumber(companyReport['close_>_60d_ma'],'close_>_60d_ma') : 'N/A' }}</div>
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">>5% Swings Count </div>
              <div class="w-[5%] px-2 bg-[#Eff2f3] h-[2.5vh] text-center font-semibold"></div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReport['>5%_swings_count'] ?
                NoDecimalFormatNumber(companyReport['>5%_swings_count']) : 'N/A' }}</div>
            </div>
            <div class="w-full px-4 space-x-0.5 flex mt-0.5 text-[10px] items-center">
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Close > 126d MA</div>
              <div class="w-[5%] px-2 bg-[#Eff2f3] text-center h-[2.5vh] font-semibold"></div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReport['close_>_126d_ma'] ?
                formatReportNumber(companyReport['close_>_126d_ma'],'close_>_126d_ma') : 'N/A' }}</div>
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Gap Down Count</div>
              <div class="w-[5%] px-2 bg-[#Eff2f3] text-center h-[2.5vh] font-semibold"></div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReport?.gap_down_count ?
                formatReportNumber(companyReport?.gap_down_count,'gap_down_count') : 'N/A' }}</div>
            </div>
            <div class="w-full px-4 space-x-0.5 flex mt-0.5 text-[10px] items-center">
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Close > 252d MA</div>
              <div class="w-[5%] px-2 bg-[#Eff2f3] h-[2.5vh] text-center font-semibold"></div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReport['close_>_252d_ma'] ?
                formatReportNumber(companyReport['close_>_252d_ma'],'close_>_252d_ma') : 'N/A' }}</div>
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Biggest Gap Down</div>
              <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">($)</div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReport['biggest_gap_down_$'] ?
                formatReportNumber(companyReport['biggest_gap_down_$']) : 'N/A' }}</div>
            </div>
            <div class="w-full px-4 space-x-0.5 flex mt-0.5 text-[10px] items-center">
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">60d MA > 200d MA</div>
              <div class="w-[5%] px-2 bg-[#Eff2f3] h-[2.5vh] text-center font-semibold"></div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReport['60d_ma_>_200d_ma'] ?
                formatReportNumber(companyReport['60d_ma_>_200d_ma'],'60d_ma_>_200d_ma') : 'N/A' }}</div>
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Biggest Gap Down</div>
              <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReport['biggest_gap_down_%'] ?
                formatReportNumber(companyReport['biggest_gap_down_%'],'biggest_gap_down_%') : 'N/A' }}</div>
            </div>
            <div class="w-full px-4 space-x-0.5 flex mt-0.5 text-[10px] items-center">
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">52 Week High</div>
              <div class="w-[5%] h-[2.5vh] pb-2 bg-[#Eff2f3] text-center font-semibold"></div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReport['52w_high'] ?
                formatReportNumber(companyReport['52w_high']) : 'N/A' }}</div>
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Cross 60d MA Count</div>
              <div class="w-[5%] h-[2.5vh] pb-2 bg-[#Eff2f3] text-center font-semibold"></div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReport?.cross_60d_ma_count ?
                formatReportNumber(companyReport?.cross_60d_ma_count,'cross_60d_ma_count') :'N/A' }}</div>
            </div>
            <div class="w-full px-4 space-x-0.5 flex mt-0.5 text-[10px] items-center">
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Low Since 52 Week High</div>
              <div class="w-[5%] px-2 bg-[#Eff2f3] pb-2 text-center font-semibold">($)</div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReport?.low_since_52_week_high ?
                formatReportNumber(companyReport?.low_since_52_week_high) : 'N/A' }}</div>
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Highest to Lowest</div>
              <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReport?.highest_to_lowest ?
                formatReportNumber(companyReport?.highest_to_lowest) : 'N/A' }}</div>
            </div>

            <div class="px-4">
              <div class="w-full border-b-2 border-[#181818] flex justify-between">
                <div class="mb-3 w-6/12 text-[10px] font-extrabold">Liquidity</div>
                <div class="mb-3 w-6/12 text-[10px] font-extrabold">Price Growth</div>
              </div>
            </div>
            <div class="w-full px-4 space-x-0.5 flex mt-0.5 text-[10px] items-center">
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Open = Close Count</div>
              <div class="w-[5%] px-2 bg-[#Eff2f3] h-[2.5vh] text-center font-semibold"></div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReport['open_=_close'] ?
                formatReportNumber(companyReport['open_=_close'],'open_=_close') : 'N/A' }}</div>
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Annual Price Growth</div>
              <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReport?.annual_growth ?
                formatReportNumber(companyReport?.annual_growth,'annual_growth') : 'N/A' }}</div>
            </div>
            <div class="w-full px-4 space-x-0.5 flex mt-0.5 text-[10px] items-center">
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">50 Day Average Turnover</div>
              <div class="w-[5%] px-2 bg-[#Eff2f3] h-[2.5vh] text-center font-semibold"></div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReport['50_turnover_average'] ?
                formatReportNumber(companyReport['50_turnover_average'],'50_turnover_average') : 'N/A' }}</div>
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Half Year Price Growth</div>
              <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReport?.half_year_growth ?
                formatReportNumber(companyReport?.half_year_growth,'half_year_growth') : 'N/A' }}</div>
            </div>
            <div class="w-full px-4 space-x-0.5 flex mt-0.5 text-[10px] items-center">
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">10 Day Average Turnover</div>
              <div class="w-[5%] px-2 bg-[#Eff2f3] h-[2.5vh] text-center font-semibold"></div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReport['10_turnover_average'] ?
                formatReportNumber(companyReport['10_turnover_average'],'10_turnover_average') : 'N/A' }}</div>
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">Last Quarter Growth</div>
              <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReport?.last_quarter_growth ?
                formatReportNumber(companyReport?.last_quarter_growth,'last_quarter_growth') : 'N/A' }}</div>
            </div>
            <div class="w-full px-4 space-x-0.5 flex mt-0.5 text-[10px] items-center">
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">50 Day Average Volume</div>
              <div class="w-[5%] px-2 bg-[#Eff2f3] h-[2.5vh] text-center font-semibold"></div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReport['volume_50d_average'] ?
                formatReportNumber(companyReport['volume_50d_average'],'volume_50d_average') : 'N/A' }}</div>
              <div class="w-[25%] bg-[#Eff2f3] pb-2 pl-2 font-semibold">20 Day Price Growth</div>
              <div class="w-[5%] px-2 pb-2 bg-[#Eff2f3] text-center font-semibold">(%)</div>
              <div class="w-[20%] bg-[#Eff2f3] pb-2 pl-1 font-semibold">{{ companyReport['20_day_growth'] ?
                formatReportNumber(companyReport['20_day_growth'],'20_day_growth') : 'N/A' }}</div>
            </div>

            <div class="px-4">
              <div class="text-[16px] text-[#000000] font-black border-b-2 -mt-1 border-black pb-2">Financial
                Performance
                Summary</div>
            </div>
            <div class="text-[#000000] px-4 text-justify mt-1 text-[10px]">
              <!-- Financial Performance Summary The company reported a profit after taxation attributable to shareholders of US$11.3 billion, compared to a profit of US$8.0 billion in FY2020. This increase in profit includes an exceptional loss of US$5.8 billion in FY2021, related to impairment charges on the company's energy coal, Potash assets, and the Samarco dam failure. The company's revenue increased by US$17.9 billion, or 42%, reaching US$60.8 billion in FY2021. This growth is attributed to higher average realized prices for iron ore, copper, nickel, oil, natural gas, and thermal coal. However, there were lower average realized prices for metallurgical coal and LNG. The company achieved record volumes at WAIO and the highest annual production at Olympic Dam, but these positives were offset by expected grade declines at Escondida and Spence, natural field decline in Petroleum, and adverse weather events. Total expenses, excluding net finance costs, increased by US$5.7 billion, or 20%, from FY2020. This includes net impairment charges on Potash assets and NSWEC, as well as higher price-linked costs due to higher royalties for iron ore and increased third-party concentrate purchase costs. Depreciation and amortization expenses increased by US$0.7 billion. -->
              {{ companyReport?.financial_summary || 'N/A' }}
            </div>

            <div class="px-4">
              <div class="w-full border-[0.2px] border-[#3f3e3e] rounded-[6px] px-2 py-1 mt-3">
                <div class="text-[14px] text-black font-semibold -mt-2">Important Notice:</div>
                <div class="w-full leading-[10px] mt-2 pb-3 text-justify">
                  <span class="text-[7.5px] font-bold text-black">
                    Market Alert Pro is an Authorised Representative (No. 001297846) of Equity Analyst Pty Ltd – ACN
                    60643403285 and AFSL 534455. Market Alert Pro provides general advice only. We do not take into
                    account any individual’s financial objectives, goals, needs or situation or life situation. Before
                    acting on any financial information we provide, you should consider its appropriateness to you and
                    we recommend you seek independent professional financial advice and read/obtain a product disclosure
                    advice (PDA) for any specific financial product you consider. Our information does not form and
                    should not be considered as personal, tailored, or one-to-one advice. Nor is it a recommendation
                    about your investment decisions. Market Alert Pro are not financial advisors and we do not hold
                    ourselves out to be financial advisors or planners. Our website, email and social media content is
                    not a substitute for professional financial advice. The information provided to you does not
                    consider your personal circumstances. The contents of our website change daily and the past
                    performance results on our website or email do not guarantee future results. The information on our
                    website or email is delivered to the general public and is not aimed at any individual. Nothing on
                    our website or email should be taken as a solicitation to buy or sell a financial product. Any
                    reliance you place on information on our website or email is strictly at your own risk. You alone
                    accept the responsibility for your investment decisions. Investing in stocks carries a risk of
                    financial loss when stock prices fall. Only trade with funds you can afford to lose.The data seen in
                    the Market Alert Pro Company Reports is usually updated annually. This is because companies are
                    typically required to report at least annual results. For the US and Canada data is usually
                    available by August and for the Australian market, the data is usually available by November. Please
                    note that Market Alert Pro has no control over when data becomes available or the accuracy or
                    completeness of the data. We do our best to keep up to date data, but cannot control the
                    availability of data. If you are uncertain about any of our data content, you should verify it for
                    yourself.
                  </span>

                </div>

              </div>
            </div>



          </div>
          <div class="w-full mt-4 text-[14px] font-medium text-[#000000] flex justify-end">
            2/2
          </div>
        </div>





      </vue-html2pdf>
      <!-- ///////////////------------------CHART------------------------------------////////////////////////////////////////// -->

      <!-- isFooter && watchListTableData.length -->

      <div class="flex bg-[#1E2224] justify-between px-4 h-[25vh] fixed mb-2 bottom-0 rounded-md footerWidth"
        v-if="isFooter && watchListTableData.length">
        <div class="w-[12%] text-left flex items-center border-r-[#7582785c] grow-[1]">
          <div class="grow-[1] h-[14vh] pl-5">
            <div>
              <div class="text-[#828282] text-[.8vw] font-semibold">Stock Info:</div>
              <div class="text-white  Inter-Bold pt-[.8vh]" style="font-size:1.1vw;">
                {{ selectedSymbolCompany }}
              </div>
              <div class="text-[#828282] text-[.8vw] font-semibold">
                {{ selectedSymbol }}
              </div>
              <div class="flex pt-[.8vh] items-center" v-if="companyChangeReport">
                <div class=" text-[1vw] font-bold"
                  :class="norGateValue?.closingDiff <= 0 ? 'text-[#FF0A00]' : 'text-[#02bd2d]'">
                  {{ norGateValue?.value.toFixed(2) }}
                </div>
                <div class=" border rounded-[2px] text-[.6vw] ml-2 flex justify-center items-center w-[6vw] h-[2.7vh]"
                  :class="norGateValue?.closingDiff <= 0 ? 'text-[#FF0A00] border-[#FF0A00] bg-[#ff0a00] bg-opacity-10' : 'text-[#02bd2d] bg-[#1a2e16] border-[#02bd2d]'">
                  {{ `${norGateValue?.closingDiff?.toFixed(2)} ` }} <span style="font-weight: 800" class="ml-1">{{ `
                    (${norGateValue?.closingPercent?.toFixed(2)}%)` }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="w-[50%] flex justify-center items-center">
          <div class="flex items-center w-[12vw]  p-2 h-fit text-[10px]" v-for="(col, i) in companyConfig" :key="i">
            <div :class="i < 2 ? 'border-r-[#828282] border-r' : ''" class="pr-4 grow-[1]">
              <div class="text-[#787C7E] text-[.8vw] h-[2vh] items-between" style="
                display: flex;
                flex-direction: column;
                justify-content: space-between;
              " v-for="(config, i) in col" :key="i">
                <div class="flex justify-between">
                  <span>{{ config.label }}</span>
                  <span class="text-white/80">{{ getReportDetails(config.column) || 'N/A' }}</span>
                </div>
                <!-- <div class="bg-[#7582785c] h-[1px] mb-1"></div> -->
              </div>
            </div>
          </div>
        </div>

        <div
          class="p-2 py-2 my-2 mr-4  bg-[#161616] rounded-[5px] border border-[#313735] w-[25%] apexChartdiv vld-parent"
          ref="areaChartHeightDiv">
          <AreaChartLoader :showChartLoader="showChartLoader" />

          <AreaChart :is-index-chart="false" :showTrend="true" class="-mt-4" :chartHeight="watchListChartHeight"
            :data-chart="symbolDataList" :symbol-name="selectedSymbol" v-if="updateChart"
            @value-emitted="onValueEmitted" />

          <!-- :watchlistReport="companyChangeReport" :isWatchlistReport = "true" -->
        </div>

        <div class='w-[1.5%] pt-1'>
          <svg @click="closeFooter" xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 24 24"
            stroke-width="1.5" stroke="white" class="w-6 h-6 cursor-pointer">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </div>
      </div>

    </div>
  </div>


</template>


<script>
import AreaChartWatchList from "../charts/AreaChartWatchList.vue";
import watchListApi from "../../api/watchListApi";
import CreateWatchModal from "@/components/popups/watch_list/CreateWatchModal";
import AddSymbolModal from "@/components/popups/watch_list/AddSymbolModal";
import AddColumnModal from "@/components/popups/watch_list/AddColumnModal";
import AlertShort from "@/components/utilities/AlertShort.vue";
import { mapGetters } from "vuex";
import FivesApi from "@/api/fivesApi";
import WatchConfig from "@/components/config/market_watch/WatchConfig";
import AreaChart from "@/components/charts/AreaChart";
import GetSymbols from "@/api/charts";
import moment from "moment/moment";
import RemoveWatchlistModal from "@/components/popups/watch_list/RemoveWatchlistModal";
import SaveTableColumn from "@/components/popups/watch_list/SaveTableColumn";
import RemoveSymbolModal from "@/components/popups/watch_list/RemoveSymbolModal";
import CopyToWatchlist from "@/components/popups/watch_list/CopyToWatchlist.vue"
import MoveToWatchlist from "@/components/popups/watch_list/MoveToWatchlist.vue"
import SkeletonTableLoader from "@/components/loader/SkeletonTableLoader.vue";
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
import AreaChartLoader from "@/components/loader/AreaChartLoader.vue";
import VueHorizontal from "vue-horizontal";
import "../customcss/index.css";
import VueHtml2pdf from "vue-html2pdf";
import draggable from 'vuedraggable'
import TailwindModal from "@/components/popups/TailwindModal";
import { watchlistHeaderDef } from '@/components/utilities/watchHeader';
import StockReportHeader from '@/components/stockalertcomponent/StockReportHeader.vue';
import StockReportFooter from '@/components/stockalertcomponent/StockReportFooter.vue'


export default {
  name: "WatchList",
  components: {
    draggable,
    AreaChart,
    VueHtml2pdf,
    SkeletonTableLoader,
    AddSymbolModal,
    AddColumnModal,
    Loading,
    AreaChartLoader,
    CreateWatchModal,
    AreaChartWatchList,
    AlertShort,
    RemoveWatchlistModal,
    RemoveSymbolModal,
    CopyToWatchlist,
    VueHorizontal,
    CopyToWatchlist,
    MoveToWatchlist,
    SaveTableColumn,
    TailwindModal,
    StockReportHeader,
    StockReportFooter
  },
  data() {
    return {
      numbersCol: ["close", "tgt_price", "tgt__close", "volume", "days_since_vol_0", "lwst_vol_30_days", "turnover", "avg_t_o_30d", "lwst_t/o_30d", "days_since_lwst_t/o", "100_mav_gap", "20_mav_gap", "5d_%_chg", "10d_%_chg", "30d_%_chg", "cls_13w_ago", "cls_26w_ago", "cls_52w_ago", "div_%", "div_$", "eps", "p_e", "est_eps_yr", "op_mgn", "bk_val", "ebitda", "roe", "shares_out"],
      showWarningPreset: false,
      openEditColumnModal: false,
      selectedColumnData: null,
      selectedColumnListData: [],
      selectedColumnListName: null,
      modalSuccessMessage: null,
      modalErrorMessage: null,
      modalLoading: false,
      openDeleteColumnModal: false,
      deletedColumnId: null,
      currentHoverId: null,
      openDeleteDropdown: false,
      openActionDropdown: false,
      openSetColumn: false,
      userSetColumnList: [],
      selectedColumnList: {},
      defaultColumnList: [{ colName: "symbol", label: "Symbol", metaInfo: true }, { colName: "company_name", label: "Company Name", metaInfo: true }, { colName: "sector", label: "Sector", metaInfo: true }, { colName: "industry", label: "Industry", metaInfo: true }, { colName: "first_traded", label: "First Traded", metaInfo: true }, { colName: "fs%", label: "Fundamental Score", metaInfo: true }, { colName: "ts%", label: "Technical Score", metaInfo: true }, { colName: "stock_alerts", label: "Stock Alert", metaInfo: true }, { colName: "confidance_indicator", label: "Confidance Indicator", metaInfo: true }, { colName: "close", label: "Close", metaInfo: true }],
      openSaveColumnModal: false,
      yearChange: "",
      enabled: true,
      dragging: false,
      footerLoader: false,
      similarCheckedArray: [],
      checkSymbol: {},
      checkmarkState: false,
      isAllColumn: false,
      isAllEditColumn: false,
      WatchConfig: [],
      copyModalState: false,
      moveModalState: false,
      currentWatchList: {},
      selectedSymbol: "",
      isFooter: false,
      tableColumns: [],
      showColumn: false,
      newTableColumn: [],
      selectedSymbolCompany: "",
      symbolDataList: [],
      isLoading: true,
      ClientHeaders: ['Symbol', 'Company Name', 'Sector', 'Industry', 'First Traded', 'Close', 'Tgt Price', 'Tgt - Close', 'Volume', 'Days Since Vol 0', 'Avg Vol 30d', '0 Vol Days 30d', 'Lwst Vol 30 Days', 'Turnover', 'Avg T/O 30d', 'Lwst T/O 30d', 'Days Since Lwst T/O', 'T/O < 10k 30d', '> 200d Avg', '200 MAV Gap', '> 100d Avg', '100 MAV Gap', '> 20d Avg', '20 MAV Gap', '13w Low', '26w Low', '52w Low', '13w High', '26w High', '52w High', '1d % Chg', '5d % Chg', '10d % Chg', '30d % Chg', 'Cls 13w Ago', 'Chg% 13w', 'Cls 26w Ago', 'Chg% 26w', 'Cls 52w Ago', 'Chg% 52w', 'Div %', 'Div $', 'EPS', 'P/E', 'Est EPS Yr', 'Revenue', 'Op Mgn', 'Bk Val', 'EBITDA', 'ROI', 'ROE', 'Shares Out', 'Mkt Cap ($M)', 'Highest Index', 'Exchange'],
      companyConfig: [
        [
          { column: "revenue", label: "Revenue ($)" },
          { column: "p/e", label: "P/E ($)" },
          { column: "div_$", label: "Dividend ($)" },
          { column: "div_%", label: "Dividend (%)" },
        ],
        [
          { column: "eps", label: "EPS ($)" },
          { column: "est_eps_yr", label: "Est EPS ($)" },
          { column: "mkt_cap", label: "Market Cap ($)" },
          { column: "roi", label: "ROI (%)" },
        ],
        [
          { column: "roe", label: "ROE (%)" },
          { column: "shares_out", label: "Share Out" },
          { column: "bk_val", label: "Book Value ($)" },
          { column: "ebitda", label: "EBITDA ($)" },
        ],
      ],
      minusValueOfHeight: 0,
      MarketWatchChartHeight:
        this.$refs.MarketWatchAreaChartDiv_Ref?.clientHeight,
      checkAllRecordsFlag: false,
      userSelectedTableRecords: [],
      // watchListChartHeight: this.$refs.areaChartHeightDiv?.clientHeight ?  this.$refs.areaChartHeightDiv?.clientHeight-20  : 0,
      watchListChartHeight: 0,
      createWatchListState: false,
      addSymbolsState: false,
      addColumnsState: false,
      removeWatchList: false,
      removeSymbol: false,
      showChartLoader: true,
      selectedWatchListID: "",
      selectedWatchList: {},
      selectedWatchListuserID: localStorage.getItem("userId"),
      selectedWatchListuserName: "",
      createdAt: null,
      updatedAt: null,
      companyReport: {},
      companyChangeReport: null,
      companyReportMetadata: null,
      selectedWatchItemCount: 0,
      watchListTableData: [],
      watchlistTableDataList: [],
      watchListApi_Data: [],
      isCreateWatchList: false,
      keyIndex: "",
      showMoveResponseMsg: "",
      showMoveMsg: false,
      showCopyResponseMsg: "",
      showCopyMsg: false,
      symbolList: '',
      norGateValue: {},
      sortOrder: { order: "asc", name: "symbol" },
      mouseOverSymbol: null,
      selectedSymbolTable: null,
      pdfLoading: false,
      chartHeight: 0,
      dataArray: [],
      companyReportDetail: {},
      headings: ["Financials", "Technicals", "Commentary"],
      //   financialKeys:["mkt_cap","debt_to_equity_st","shares_float","shares_out","cash_per_share","net_profit_5_yr_av","p/e","eps","op_mgn","ebitda","revenue","net_profit_5_yr_av","net_income","mrfy_earnings_before_taxes","gross_margin","quick_ratio","current_ratio","roi","roe","roa","free_cash_flow","revenue_change_1_yr","revenue_change_3_yr","bk_val","div_$","div_yield_1_yr","div_growth_3_yr","div_avg_5_yr","frank_pct_(au)","payout_ratio"],
      financialKeys: ["p/e", "eps", "op_mgn", "ebitda", "revenue", "net_profit", "net_income", "mrfy_earnings_before_taxes", "gross_margin", "quick_ratio", "current_ratio", "roi", "roe", "roa", "free_cash_flow", "revenue_change_1_yr", "revenue_change_3_yr", "bk_val", "div_$", "div_%", "div_growth_3_yr", "div_avg_5_yr", "frank_pct_(au)", "payout_ratio", "cash_per_share", "shares_out", "shares_float", "debt_to_equity_st", "mkt_cap", "shares_float", "interest_coverage"],
      technicalKeys: ["close_>_60d_ma", "close_>_126d_ma", "close_>_252d_ma", "60d_ma_>_200d_ma", "52w_high", "low_since_52_week_high", ">5%_swings_count", "gap_down_count", "biggest_gap_down_$", "biggest_gap_down_%", "cross_60d_ma_count", "highest_to_lowest", "open_=_close", "50_turnover_average", "10_turnover_average", "volume_50d_average", "annual_growth", "half_year_growth", "last_quarter_growth", "20_day_growth"],
      commentoryKeys: ["overview", "net_profit", "net_debt", "biggest_gap_down_%", "revenue_growth_5_yrs", "debt_growth_5_yrs", "assets_to_liabilities_ratio", "debt_to_equity_lt", "div_%"],
      selectedHeader: "Financials",
      titleMapping: {
        'p/e': "P/E ($)",
        eps: "EPS ($)",
        op_mgn: "Operating Margin (%)",
        ebitda: "EBITDA ($)",
        revenue: "Total Revenue ($)",
        net_profit: "Net Profit (%)",
        net_income: "Net Income ($)",
        mrfy_earnings_before_taxes: "Pre-Tax Income ($)",
        gross_margin: "Gross Margin (%)",
        quick_ratio: "Quick Ratio (%)",
        current_ratio: "Current Ratio (%)",
        roi: "ROI (%)",
        roe: "ROE (%)",
        roa: "ROA (%)",
        free_cash_flow: "Free Cash Flow ($)",
        revenue_change_1_yr: "Revenue Change 1 Yr (%)",
        revenue_change_3_yr: "Revenue Change 3 Yr (%)",
        bk_val: "Book Value per Share ($)",
        'div_$': "Dividend ($)",
        'div_%': "Div Yield (%)",
        div_growth_3_yr: "Dividend Growth 3 Yrs (%)",
        div_avg_5_yr: "Dividend Average 5 Yrs ($)",
        'frank_pct_(au)': "Franking (Australia only) (%)",
        payout_ratio: "Payout Ratio (%)",
        cash_per_share: "Cash per Share (%)",
        shares_out: "Shares Outstanding",
        shares_float: "Shares float",
        debt_to_equity_st: "Debt to Equity ST (%)",
        mkt_cap: "Market Cap",
        interest_coverage: "Interest Coverage (%)",
        'close_>_60d_ma': "Close > 60d MA",
        'close_>_126d_ma': "Close > 126d MA",
        'close_>_252d_ma': "Close > 252d MA",
        '60d_ma_>_200d_ma': "60d Ma >200d MA",
        '52w_high': "52 Week High",
        'low_since_52_week_high': "Low Since 52 Week High ($)",
        '>5%_swings_count': ">5% Swings Count",
        'gap_down_count': "Gap Down Count",
        'biggest_gap_down_$': "Biggest Gap Down ($)",
        'biggest_gap_down_%': "Biggest Gap Down (%)",
        'cross_60d_ma_count': "Cross 60d MA Count",
        'highest_to_lowest': "Highest To Lowest (%)",
        'open_=_close': "Open = Close",
        '50_turnover_average': "50 Day Average Turnover",
        '10_turnover_average': "10 Day Average Turnover",
        'volume_50d_average': "50 Day Average Volume",
        'annual_growth': "Annual Price Growth (%)",
        'half_year_growth': "Half Year Price Growth (%)",
        'last_quarter_growth': "Last Quarter Price Growth (%)",
        '20_day_growth': "20 Day Price Growth (%)"


      },
      symbolChartData: [],
      selectedChartSymbol: "",
      reportLoading: false,
      symbolDetail: null,
      listData: {},
      reportDataKeyValue: {
        "financial_performance_overview": "Financial Performance",
        "management_summary": "Management Summary",
        "market_outlook": "Market Outlook",
        "risk_factors": "Risk Factors",
        "financial_measures": "Financial Measures",
        "growth_strategy": "Growth Strategy",
        "legal_proceedings": "Legal Proceedings",
        "industry_outlook": "Industry Outlook"
      }
    };
  },

  computed: {
    ...mapGetters(["signedInUser", "exchangeToCountry"]),
    updateChart() {
      let updateChartDecision = this.symbolDataList?.length > 0;
      console.log('updateChartDecision', updateChartDecision)
      return updateChartDecision;
    },
    CompanyChangeComputed() {
      // console.log("company change data",this.companyChangeReport.closingDiff,"percent",this.companyChangeReport.closingPercent)
      return `${this.norGateValue?.closingDiff} (${this.norGate?.closingPercent})`;
    },
  },

  methods: {
    getDownloadName() {
      return `Market_Alert_Pro_Company_Report_${this.companyReport?.symbol}_${this.getToday_Date()}`
    },
    getTableDef(col) {
      if (watchlistHeaderDef[col?.colName]) {
        return watchlistHeaderDef[col?.colName]
      } else {
        return col?.label
      }
    },
    openWarningPresent() {
      this.showWarningPreset = true
    },
    closeWarningPreset() {
      this.showWarningPreset = false
    },
    handleTableMouseItemLeave() {
      this.mouseOverSymbol = null
    },
    handleTableMouseItem(item) {
      this.mouseOverSymbol = item?.name
    },
    async handleDeleteColumn() {
      this.showWarningPreset = false
      try {
        this.modalLoading = true
        const res = await watchListApi.deleteColumn(this.selectedColumnData?._id)
        if (res?.data?.success) {
          this.modalSuccessMessage = "Preset deleted successfully"
          setTimeout(() => {
            this.closeEditColumnModal()
            this.modalLoading = false
          }, 2000);
          this.getTableColumnList()
        } else {
          this.modalErrorMessage = res?.data?.message
          this.modalLoading = false
        }

      } catch (err) {
        this.modalErrorMessage = "Server error"
        this.modalLoading = false

      }
    },
    handleColumnDelete(id) {
      setTimeout(() => {
        this.openSetColumn = false
        this.deletedColumnId = id
        this.openDeleteColumnModal = true
      }, 100);

    },
    async saveEditColumnModal() {
      try {
        this.modalLoading = true
        const payload = {
          id: this.selectedColumnData?._id,
          ColumnList: this.selectedColumnListData,
          name: this.selectedColumnListName
        }
        console.log("payload==>", payload)
        const res = await watchListApi.editColumn(payload)
        if (res?.data?.success) {
          this.modalSuccessMessage = res?.data?.message
          this.getTableColumnList()
          setTimeout(() => {
            this.closeEditColumnModal()
          }, 2000)
        } else {
          this.modalErrorMessage = res?.data?.message
        }
      } catch (error) {
        this.modalErrorMessage = "Server error"
      }
      this.modalLoading = false
    },

    handleEditColumn(item) {
      console.log("edit====>")
      setTimeout(() => {
        this.openSetColumn = false
        this.selectedColumnData = item
        this.selectedColumnListData = item?.columnList
        this.selectedColumnListName = item?.name
        this.openEditColumnModal = true
      }, 100);
    },
    closeEditColumnModal() {
      this.openEditColumnModal = false
      this.selectedColumnData = null
      this.selectedColumnListData = []
      this.selectedColumnListName = null
      // this.getTableColumnList()
    },
    closeDeleteColumnModal() {
      this.openDeleteColumnModal = false
    },
    handleCurrentHoverColumn(id) {
      this.currentHoverId = id
    },
    handleAllColumn() {
      this.isAllColumn = !this.isAllColumn
    },
    handleAllEditColumn() {
      this.isAllEditColumn = !this.isAllEditColumn
    },
    handleDeleteDropdown() {
      this.openDeleteDropdown = !this.openDeleteDropdown
      this.openSetColumn = false
      this.openActionDropdown = false
    },
    handleActionsDropdown() {
      this.openActionDropdown = !this.openActionDropdown
      this.openDeleteDropdown = false
      this.openSetColumn = false
    },
    handleSelectColumnDropdown() {
      this.openSetColumn = !this.openSetColumn
      this.openActionDropdown = false
      this.openDeleteDropdown = false
    },
    async handleSetColumnSelect(item) {
      this.selectedColumnList = item
      this.newTableColumn = item?.columnList
      localStorage.setItem("tableColumns", JSON.stringify(this.newTableColumn));
      // const res = await watchListApi.saveLastColumn(item?._id)
      // if(res?.data?.success){
      //   console.log("save")
      // }else{
      //   console.log("error")
      // }      
    },

    handleSorting(key) {
      const checkDataType = typeof this.watchlistTableDataList[0]?.metadata[key]
      const checkDataTypesList = ["string", "number"]
      console.log("check==>", checkDataTypesList?.includes(checkDataType), checkDataType)
      if (checkDataTypesList?.includes(checkDataType)) {
        if (this.sortOrder?.order == "asc") {
          let sortedDescending = []
          if (checkDataType == "string") {
            sortedDescending = this.watchlistTableDataList?.slice().sort((a, b) => b.metadata[key]?.localeCompare(a.metadata[key]));
          } else if (checkDataType == "number") {
            sortedDescending = this.watchlistTableDataList?.slice().sort((a, b) => b.metadata[key] - a.metadata[key]);
          }
          this.watchlistTableDataList = sortedDescending
          this.sortOrder = { order: "desc", name: key }
        }
        else if (this.sortOrder?.order == "desc") {
          let sortedAscending = []
          if (checkDataType == "string") {
            sortedAscending = this.watchlistTableDataList?.slice().sort((a, b) => a.metadata[key]?.localeCompare(b.metadata[key]));
          } else if (checkDataType == "number") {
            sortedAscending = this.watchlistTableDataList?.slice().sort((a, b) => a.metadata[key] - b.metadata[key]);
          } else {
            return
          }
          this.watchlistTableDataList = sortedAscending
          this.sortOrder = { order: "asc", name: key }
        }
      }
    },


    handleOpenSaveModal() {
      this.openActionDropdown = false
      this.openDeleteDropdown = false
      this.openSetColumn = false
      this.openSaveColumnModal = true
    },
    processString(inputString, maxLength = 14) {
      if (inputString) {
        if (inputString?.length > maxLength) {
          return inputString?.slice(0, maxLength) + '..';
        } else {
          return inputString;
        }
      }
    },



    onValueEmitted(value) {
      if (value) {


        //         this.norGateValue = Object.entries(value).reduce((acc, [key, value]) => {
        //   acc[key] = Number(value);
        //   console.log("acc return updar ",acc)
        //   if (isNaN(acc[key])) {
        //     return acc[key]

        //   }
        //   console.log("return acc",acc)
        //   return acc;
        // }, {});

        for (let key in value) {
          value[key] = parseFloat(value[key].replace(/,/g, ''));
          if (value[key] === 0) {
            value[key] = 0.00;
          }
        }
        console.log("value", value)
        this.norGateValue = value


        // console.log("value recieved",this.norGateValue)
      }
    },

    async handleCopySymbol(watchId) {
      console.log(watchId)
      //  let date = moment().format('MMM, DD YYYY');
      let date = moment().format('MM/DD/YYYY');
      const payload = {
        watchId: watchId,
        symbolList: this.userSelectedTableRecords,
        updatedDate: date
      }

      const res = await watchListApi.copySymbols(payload)
      if (res.data.success) {
        this.showCopyMsg = true
        this.showCopyResponseMsg = "Your Symbol(s) has been copied successfully"
        console.log("response move symbol", res);
        this.userSelectedTableRecords = []
        this.fetchwatchListApi_Data();
      } else {
        this.showCopyMsg = true
        this.showCopyResponseMsg = "Server Error"
        this.fetchwatchListApi_Data();
      }
    },


    async handleMoveSymbol(watchId) {
      console.log(watchId)
      console.log("hello from move symbol");
      // let date = moment().format('MMM, DD YYYY');
      let date = moment().format('MM/DD/YYYY');

      // let watchId = this.watchlistData[this.newWatchName]._id
      const payload = {
        watchId: watchId,
        symbolList: this.userSelectedTableRecords,
        updatedDate: date,
        previousWatchId: this.currentWatchList._id
      }

      const res = await watchListApi.moveSymbols(payload)
      if (res.data.success) {
        this.showMoveMsg = true
        this.showMoveResponseMsg = "Your Symbol(s) has been moved successfully"
        console.log("response move symbol", res);
        this.fetchwatchListApi_Data();

      } else {
        this.showMoveMsg = true
        this.showMoveResponseMsg = "Server Error"
        this.fetchwatchListApi_Data();

      }

    },

    toggleCopyModal() {
      this.showCopyMsg = false
      this.copyModalState = false

    },

    handleCopyWatchlist() {
      this.copyModalState = true
    },

    toggleMoveModal() {
      console.log("close modal")
      this.showMoveMsg = false
      this.moveModalState = false

    },

    handleMoveWatchlist() {
      this.moveModalState = true
    },

    // getMetaDataClass(columnName,columnValue){
    //   if(columnValue < 0){
    //     return 'text-[red]'
    //   }

    // },
    checkMove: function (e) {
      this.swapElements(e.draggedContext.index, e.draggedContext.futureIndex)
    },

    getDropDownList() {
      let data = localStorage.getItem("WatchConfig");
      this.WatchConfig = WatchConfig;
      console.log("dropdown data", this.WatchConfig, WatchConfig)
    },

    swapElements(startInd, futureIndex) {
      console.log("start index", startInd)
      console.log("FUTURE index", futureIndex)

      // let temp = this.WatchConfig[startInd];

      // this.WatchConfig[startInd] = this.WatchConfig[futureIndex];

      // this.WatchConfig[futureIndex] = temp;

      // localStorage.setItem("WatchConfig",JSON.stringify(this.WatchConfig))

      // this.getDropDownList()

    },

    closeFooter() {
      this.watchListChartHeight = 0
      this.isFooter = false
      this.keyIndex = ""
    },
    getTodayDate() {
      return moment().format('D/MM/YYYY')
    },
    getToday_Date() {
      return moment().format('D_MM_YYYY')
    },
    onProgress(event) {
      console.log(`Processed: ${event} / 100`);
    },
    hasGenerated() {
      alert("PDF generated successfully!");
    },
    generatePDF() {
      this.$refs.html2Pdf.generatePdf();
    },

    handleColumnToggle() {
      this.showColumn = !this.showColumn;
    },

    handleEditColumnValues(item) {
      if (event.target.checked === true) {
        let data = JSON.parse(JSON.stringify(this.selectedColumnListData));
        data?.push(item)
        console.log("data==>", data)
        const x = this.WatchConfig?.filter((elem) => {
          return data?.some((ele) => {
            return ele.label === elem.label;
          });
        });
        this.selectedColumnListData = x;
      } else {
        let data = JSON.parse(JSON.stringify(this.selectedColumnListData));
        data = data?.filter((x) => {
          if (x.label != item.label) { return x }
        });
        this.selectedColumnListData = data;
      }
      console.log("selected==>", this.selectedColumnListData)

    },
    handleColumn(item) {
      console.log("item====>", item)

      if (event.target.checked === true) {
        let data = [];
        if (localStorage.getItem("tableColumns")) {
          data = localStorage.getItem("tableColumns");
          data = JSON.parse(data);
        }
        data.push(item);

        const newTableColumn = this.WatchConfig?.filter((elem) => {
          return data.some((ele) => {
            return ele.label === elem.label;
          });
        });
        localStorage.setItem("tableColumns", JSON.stringify(newTableColumn));
        this.tableColumns = newTableColumn;
      } else {
        // console.log("unchecked", item);
        let data = []
        data = localStorage.getItem("tableColumns");
        data = JSON.parse(data);
        data = data.filter((x) => {
          if (x.label != item.label) { return x }
        });
        // console.log("data from local storage",data);
        // const index= data.findIndex((x)=> x==item)
        // console.log("indexc",index);
        // console.log("CHECKROWS",this.tableColumns )
        //  data.splice(index,1)
        localStorage.setItem("tableColumns", JSON.stringify(data));
        this.tableColumns = data;
      }

      // this.tableColumns.push(value)
      // console.log("tableColumn",this.tableColumns)
    },

    // handleAddColumnModelState(){
    //   this.addColumnsState = true
    // },

    getWatchTableClass(item, config) {
      if (config.metaInfo) {
        if (item.metadata) {
          if (item.metadata[config.colName] < 0 && config.colName != 'ebitda') {
            return "text-[#FF0A00] text-left";
          }

          if (config.colName == 'ebitda') {
            if (item.metadata['ebitda'] <= -1) {
              return "text-[#FF0A00] text-left";
            }
          }

          if (config.colName == 'market_heat') {
            const val = item.metadata['market_heat']
            if (val) {
              const splitVal = val?.split(" ")
              const lastVal = splitVal[splitVal?.length - 1]
              if (lastVal == 'Up') {
                return 'text-[#02BD2D]'
              } else {
                return "text-[#FF0A00]";
              }
            }
          }
          if (config.colName == 'fs%') {
            const val = item.metadata['fs%']
            if (val < 40) {
              return 'text-[#FF0A00]'
            } else if (val >= 40 && val < 70) {
              return 'text-[#FED500]'
            } else {
              return 'text-[#02BD2D]'
            }
          }
          if (config.colName == 'ts%') {
            const val = item.metadata['ts%']
            if (val < 40) {
              return 'text-[#FF0A00]'
            } else if (val >= 40 && val < 70) {
              return 'text-[#FED500]'
            } else {
              return 'text-[#02BD2D]'
            }
          }
          if (config.colName == 'confidance_indicator') {
            const val = item.metadata['confidance_indicator']
            if (val <= 1.5 && item.metadata['confidance_up'] == 0) {
              return 'text-[#FF0A00]'
            } else if (val <= 1.5 && item.metadata['confidance_up'] == 1) {
              return 'text-[#FED500]'
            } else if (val > 1.5) {
              return 'text-[#02BD2D]'
            }
          }
          if (config.colName == 'stock_alerts') {
            const val = item.metadata['stock_alerts'].trim()
            if (val == 'Stock Alert') {
              return 'text-[#FF0A00]'
            } else if (val == 'MBA Down') {
              return 'text-[#FED500]'
            } else {
              return 'text-[#02BD2D]'
            }
          }

          if (typeof item.metadata[config.colName] == "number") {
            return "text-left"
          }
        }
        else {
          return "text-[#DBDCDC]"
        }



        if (item.metadata[config.colName] == null) {
          return "text-[#DBDCDC]"
        }

      }
    },

    NoDecimalFormatNumber(val) {
      const num = typeof val === 'string' ? parseFloat(val.replace(/,/g, '')) : val;
      if (isNaN(num)) {
        return val; // Return the original value if it's not a valid number
      }
      const isNegative = num < 0;
      const absoluteValue = Math.abs(num);
      if (absoluteValue >= 1e12) {
        return (isNegative ? '-' : '') + (absoluteValue / 1e12).toFixed(0) + 'T';
      } else if (absoluteValue >= 1e9) {
        return (isNegative ? '-' : '') + (absoluteValue / 1e9).toFixed(0) + 'B';
      } else if (absoluteValue >= 1e6) {
        return (isNegative ? '-' : '') + (absoluteValue / 1e6).toFixed(0) + 'M';
      } else if (absoluteValue >= 1e3) {
        return (isNegative ? '-' : '') + (absoluteValue / 1e3).toFixed(0) + 'K';
      } else {
        if (isNegative) {
          return '-' + absoluteValue?.toFixed(0)
        } else {
          return absoluteValue.toFixed(0)
        }
      }
    },

    formatReportNumber(val, col = null) {
      const num = typeof val === 'string' ? parseFloat(val.replace(/,/g, '')) : val;
      let alwaysTwo = ['volume_50d_average', '50_turnover_average', '10_turnover_average', '20_day_growth', 'div_%', 'highest_to_lowest', 'revenue', 'op_mgn', 'ebitda', 'roe', 'roi', 'shares_out', 'shares_float', 'mkt_cap', 'frank_pct_(au)', 'net_income', 'gross_margin', 'quick_ratio', 'free_cash_flow', 'revenue_change_1_yr', 'revenue_change_3_yr', 'div_growth_3_yr', 'div_avg_5_yr', 'payout_ratio', 'cash_per_share', 'interest_coverage', 'debt_to_equity_st', 'current_ratio', 'roa', 'annual_growth', 'half_year_growth', 'last_quarter_growth', 'revenue_growth_5_yrs', 'debt_growth_5_yrs', 'assets_to_liabilities_ratio', 'debt_to_equity_lt', 'biggest_gap_down_%', 'div_yield_1_yr', 'net_profit', 'net_debt', 'debt_growth_5_yrs', 'free_cash_flow', 'qrtly_rev_growth', 'peg_ratio']
      let alwaysThree = ['split_ratio']
      let noDecimal = ['open_=_close', '>5%_swings_count', 'cross_60d_ma_count', 'close_>_60d_ma', 'close_>_126d_ma', 'close_>_252d_ma', '60d_ma_>_200d_ma', 'gap_down_count']

      if (isNaN(num)) {
        return val; // Return the original value if it's not a valid number
      }

      const isNegative = num < 0;
      const absoluteValue = Math.abs(num);


      let fixedValue;
      if (alwaysTwo?.includes(col)) {
        fixedValue = 2
      } else if (alwaysThree?.includes(col)) {
        fixedValue = 3
      } else if (noDecimal?.includes(col)) {
        fixedValue = 0
      }
      else if (absoluteValue < 1) {
        fixedValue = 3;
      } else {
        fixedValue = 2;
      }

      // if(col == 'shares_float'){
      //  if(isNegative){
      //     return '-' + absoluteValue?.toLocaleString()
      //  }else{
      //     return absoluteValue?.toLocaleString()
      //  }
      // }else{
      if (absoluteValue >= 1e12) {
        return (isNegative ? '-' : '') + (absoluteValue / 1e12).toFixed(fixedValue) + 'T';
      } else if (absoluteValue >= 1e9) {
        return (isNegative ? '-' : '') + (absoluteValue / 1e9).toFixed(fixedValue) + 'B';
      } else if (absoluteValue >= 1e6) {
        return (isNegative ? '-' : '') + (absoluteValue / 1e6).toFixed(fixedValue) + 'M';
      } else if (absoluteValue >= 1e3) {
        if (col == 'free_cash_flow') {
          if (absoluteValue >= 10000) {
            return (isNegative ? '-' : '') + (absoluteValue / 1e3).toFixed(fixedValue) + 'K';
          } else {
            return (isNegative ? '-' : '') + absoluteValue?.toLocaleString();
          }
        } else {
          return (isNegative ? '-' : '') + (absoluteValue / 1e3).toFixed(fixedValue) + 'K';
        }
      } else {
        if (isNegative) {
          if (alwaysTwo?.includes(col)) {
            return '-' + absoluteValue?.toFixed(2)
          } else if (alwaysThree?.includes(col)) {
            return '-' + absoluteValue?.toFixed(3)
          } else if (noDecimal?.includes(col)) {
            return '-' + absoluteValue?.toFixed(0)
          }
          else {
            return '-' + absoluteValue?.toFixed(3)
          }
        } else {
          return absoluteValue.toFixed(fixedValue)
        }
      }


      // }


    },


    formatNumber(val) {
      const num = typeof val === 'string' ? parseFloat(val.replace(/,/g, '')) : val;

      if (isNaN(num)) {
        return val; // Return the original value if it's not a valid number
      }

      const isNegative = num < 0;
      const absoluteValue = Math.abs(num);

      if (absoluteValue >= 1e12) {
        return (isNegative ? '-' : '') + (absoluteValue / 1e12).toFixed(2) + 'T';
      } else if (absoluteValue >= 1e9) {
        return (isNegative ? '-' : '') + (absoluteValue / 1e9).toFixed(2) + 'B';
      } else if (absoluteValue >= 1e6) {
        return (isNegative ? '-' : '') + (absoluteValue / 1e6).toFixed(2) + 'M';
      } else if (absoluteValue >= 1e3) {
        return (isNegative ? '-' : '') + (absoluteValue / 1e3).toFixed(2) + 'K';
      } else {
        return (isNegative ? '-' : '') + absoluteValue.toFixed(2);
      }
    },

    getWatchInfo(item, config) {
      // console.log("config",config,item)
      if (config.metaInfo) {
        if (item.metadata) {

          if ((config.colName == "ex_dividend" && item.metadata['ex_dividend'] == 0) || (config.colName == "dividend_pay" && item.metadata['dividend_pay'] == 0)) {
            return 'N/A'
          }


          if (item.metadata[config.colName] == 0) {
            if (config.colName == "t/o_ls_than_100k_20d" || config.colName == "frank_pct_au") {
              return '0'
            } else {
              return "0.00"
            }
          }


          if (item.metadata[config.colName] == null) {
            // console.log("item.metadata[config.colName] null wala if",item.metadata[config.colName])

            return "N/A"
          }
          if (item.metadata[config.colName] == "") {
            // console.log("item.metadata[config.colName] null wala if",item.metadata[config.colName])

            return "N/A"
          }
          if (item.metadata[config.colName] == "N/A") {
            return "N/A"
          }


          if (config.colName == "company_name") {
            if (item.metadata[config.colName]) {
              if (this.newTableColumn?.length < 7) {
                return item.metadata[config.colName]
              } else {
                return this.processString(item.metadata[config.colName], 15)
              }
            }
          }
          if (config.colName == "industry") {
            if (item.metadata[config.colName]) {
              if (this.newTableColumn?.length < 7) {
                return item.metadata[config.colName]
              } else {
                return this.processString(item.metadata[config.colName], 15)
              }
            }
          }
          if (config.colName == "sector") {
            if (item.metadata[config.colName]) {
              if (this.newTableColumn?.length < 7) {
                return item.metadata[config.colName]
              } else {
                return this.processString(item.metadata[config.colName], 15)
              }
            }
          }


          if (config.colName == "volume" || config.colName == 'turnover' || config.colName == 'lwst_vol_30_days' || config.colName == 'avg_t_o_30d' || config.colName == 'lwst_t/o_30d' || config.colName == 'revenue' || config.colName == 'shares_out' || config.colName == 'mkt_cap_m' || config.colName == 'ebitda' || config.colName == 'avg_vol_30d') {
            if (item.metadata[config.colName]) {
              const number = item.metadata[config.colName]
              const integerVal = parseInt(number)
              return this.formatReportNumber(integerVal, config.colName)
              // return this.formatNumber(integerVal)
            }
          }

          if (config.colName == "close" || config.colName == "tgt_price" || config.colName == "tgt__close" || config.colName == "div_$" || config.colName == "eps" || config.colName == "div_%" || config.colName == "ebitda" || config.colName == "bk_val" || config.colName == "op_mgn" || config.colName == "est_eps_yr" || config.colName == "roi" || config.colName == "roe" || config.colName == "p_e" || config.colName == "1d_%_chg" || config.colName == "5d_%_chg" || config.colName == "10d_%_chg" || config.colName == "30d_%_chg" || config.colName == "chg%_13w" || config.colName == "cls_13w_ago" || config.colName == "cls_26w_ago" || config.colName == "chg%_26w" || config.colName == "cls_52w_ago" || config.colName == "chg%_52w" || config.colName == "days_since_lwst_t/o" || config.colName == "200_mav_gap" || config.colName == "100_mav_gap" || config.colName == "20_mav_gap") {
            if (item.metadata[config.colName]) {
              const num = item.metadata[config.colName]
              return this.formatReportNumber(num, config.colName)
              // return num?.toFixed(2)
            }
          }

          if (config.colName == "t/o_ls_than_100k_20d" || config.colName == "frank_pct_au") {
            if (item.metadata[config.colName]) {
              const number = item.metadata[config.colName]
              const integerVal = parseInt(number)
              console.log("val==>", integerVal)
              return integerVal?.toFixed(0)
            }
          }

          if (config.colName == "first_traded" || config.colName == "dividend_pay" || config.colName == "ex_dividend") {
            if (item.metadata[config.colName]) {
              const dateString = item.metadata[config.colName];
              const parsedDate = moment(dateString);
              const formattedDate = parsedDate?.format('DD-MM-YYYY');
              return formattedDate;
            }
          }

          else {
            // console.log("item.metadata[config.colName]====>",item.metadata[config.colName])
            return item.metadata[config.colName];
          }
        }
        // else {
        //   return "N/A";
        // }
      }
      return item[config.colName];
    },

    formatDate(d) {

      return moment(d).format("DD/MM/YYYY [at] h:m:A");
    },

    getCompanyDetails(column) {
      // console.log("column =====>",column)

      // console.log("this.companyReportMetadata =====>",this.companyReportMetadata)

      return this.companyReportMetadata && this.companyReportMetadata[column] !== undefined
        ? this.companyReportMetadata[column]
        : "N/A";
    },



    getReportDetails(column) {
      return this.companyReport && this.companyReport[column] !== undefined && this.companyReport[column] !== null
        ? this.formatReportNumber(this.companyReport[column], column)
        : "N/A";
    },

    async getCompanyChangeReport(assetID) {
      this.showChartLoader = true;
      let companyChangeReport = null;
      if (assetID) {
        let apiData = await FivesApi.fetchSnapQuotes([assetID]);
        if (apiData?.data && apiData?.data.length > 0) {
          //  update changes
          this.showChartLoader = false;
          let data = apiData.data[0];
          companyChangeReport = apiData.data[0];

          console.log("company change report", this.companyChangeReport);
        }
      }
      if (companyChangeReport) {
        this.showChartLoader = false;
        this.companyChangeReport = companyChangeReport;
      }
    },
    async getCompanyReport(value, k) {
      console.log("company report all column", value.name);
      console.log("company report value", value);
      this.keyIndex = value.name
      this.selectedSymbolTable = value.name
      console.log("company report this.keyIndex", this.keyIndex);
      // this.footerLoader = true 
      if (value?.metadata) {
        this.isFooter = true;

        this.companyReportMetadata = value.metadata
        console.log("company report metadata", this.companyReportMetadata)
        this.footerLoader = false
        let countryS = value.metadata?.symbol.split('.')
        let country;
        console.log("country[1]", countryS[1])
        if (countryS[1] === 'au') {
          country = 'oz'
        }
        else if (countryS[1] === 'ca') {
          country = 'ca'
        }
        else {
          country = 'us'
        }
        console.log("country", country)


        let symbolDataList = await GetSymbols.getSymbol(
          value.name,
          "D",
          value.metadata,
          5400
        );
        console.log("symbole data list", symbolDataList);
        let assetID = symbolDataList?.assetID;
        symbolDataList = symbolDataList?.data ? symbolDataList?.data : [];
        console.log("symbolDataList", symbolDataList);
        this.selectedSymbol = value.name;
        this.selectedSymbolCompany = value.companyName
        this.symbolDataList = symbolDataList;
        this.showChartLoader = false;
        // let country = this.exchangeToCountry[myCountry]
        let ticker = value.name;
        console.log("country", country, "ticker", ticker);

        // let result = await FivesApi.getCompanyReport(
        //   { symbol: ticker },
        //   country
        // );

        let result = await FivesApi.getCompanyReportStockAlert(ticker);

        this.dataArray = []
        console.log("result", result);
        let payload = result.data.payload;
        this.companyReportDetail = payload
        this.companyReportDetail['overview'] = this.companyReportDetail?.overview?.replace(/&amp;/g, '&')
        console.log("payload=====>", payload)
        this.companyReport = payload ? payload : {};
        const filteredData = {};
        Object.keys(this.reportDataKeyValue).forEach(key => {
          if (key in this.companyReportDetail && this.companyReportDetail[key] !== "") {
            filteredData[key] = this.companyReportDetail[key].replace(/&amp;/g, '&');
          }
        });

        Object.keys(filteredData).map((key) => {
          this.dataArray.push({
            "key": this.reportDataKeyValue[key],
            "value": filteredData[key]
          })
        })

        console.log("company report =>", this.companyReport);
        await this.getCompanyChangeReport(assetID);
      }
      // this.footerLoader = false

      this.watchListChartHeight =
        this.$refs.areaChartHeightDiv.clientHeight - 60;
      console.log("Height", this.watchListChartHeight);
    },

    fetchwatchListApi_Data: async function (index = 0) {
      try {
        const res = await watchListApi.fetchWatchListData();
        console.log("watch list response", res.data.payload);
        if (res.data.success) {
          console.log("fetched====>");
          console.log("data at index 0", res.data.payload[index]);
          this.isLoading = false;
          console.log("success", this.isLoading);
          this.watchListApi_Data = res.data.payload;
          console.log("response watchlistapi data", this.watchListApi_Data);

          if (res.data.payload && res.data.payload.length > 0) {
            this.handleUserSelectedWatchList(res.data.payload[index]);
          } else {
            this.isLoading = false;
            this.watchListTableData = [];
            this.selectedWatchListuserName = "";
            this.companyReport = {};
          }
        }
      } catch (err) {
        this.isLoading = false;
        console.log("something went wrong", err);
      }
    },
    onResize() {
      // this.watchListChartHeight =
      //   this.$refs.areaChartHeightDiv.clientHeight - 23;
      this.watchListChartHeight =
        this.$refs.areaChartHeightDiv.clientHeight - 60;
      this.$nextTick(() => {
        // this.watchListChartHeight =
        //   this.$refs.areaChartHeightDiv.clientHeight - 23;
        this.watchListChartHeight =
          this.$refs.areaChartHeightDiv.clientHeight - 60;
      });
    },
    handleRemoveSymbol: async function () {
      console.log(
        "watch",
        this.watchListApi_Data.findIndex((x) => x == this.currentWatchList)
      );
      if (this.userSelectedTableRecords.length) {

        // let date = moment().format('MMM, DD YYYY');
        let date = moment().format('MM/DD/YYYY');
        try {
          const payload = {
            watchId: this.selectedWatchListID,
            symbolList: this.userSelectedTableRecords,
            updatedDate: date
          };
          const res = await watchListApi.removeWatchListRecord(payload);
          this.isFooter = false
          console.log("delete record", res);
          if (!res.data.isError) {
            let index = this.watchListApi_Data.findIndex(
              (x) => x == this.currentWatchList
            );
            this.fetchwatchListApi_Data(index);
            this.removeSymbol = false;
          }
        } catch (err) {
          console.log("api call failed");
        }
      } else {
        console.log("no dates selected");
      }
    },
    handleAddSymbolModelState() {
      this.addSymbolsState = true;
    },
    handleCreateWacthListModelState() {
      this.createWatchListState = true;
    },
    handleRemoveWatchListModalState() {
      this.removeWatchList = true;
    },
    handleRemoveSymbolModalState() {
      this.removeSymbol = true;
    },
    handleSelectedTableRecord(value) {
      let index = null
      if (event.target.checked) {
        this.similarCheckedArray.push(value.name)
        this.userSelectedTableRecords.push({ name: value.name, id: value._id });
      } else {
        // this.userSelectedTableRecords = this.userSelectedTableRecords.filter(
        //   (item) => item._id != value._id
        // );
        let a = { name: value.name, id: value._id }
        index = this.similarCheckedArray.indexOf(value.name)
        this.userSelectedTableRecords.splice(index, 1)
        this.similarCheckedArray.splice(index, 1)
      }
      console.log("userSelectedTableRecords ", this.userSelectedTableRecords, "index is ", index)
    },

    selectedButtonClass(value) {
      if (value === this.selectedWatchListID) {
        return "border border-[#a6a8a8] tracking-wider rounded-[3px] bg-white text-[12px] text-black ml-3 font-bold cursor-pointer";
      } else {
        return "border rounded-[3px] tracking-wider border-[#dbdcdc] bg-[#1e2224] text-[#dbdcdc] text-[12px] ml-3 font-bold cursor-pointer";
      }
    },
    handleSelectAllTableRecords() {
      if (event.target.checked) {
        this.userSelectedTableRecords = [];
        this.watchListTableData[0].symbolList.map((x) => {
          this.userSelectedTableRecords.push({
            name: x.name,
            id: x.id,
          });
        });
        this.checkAllRecordsFlag = true;
      } else {
        this.userSelectedTableRecords = [];
        this.checkAllRecordsFlag = false;
      }
    },

    formateDateToDMY(inputDateString) {
      if (inputDateString) {
        let formattedDate;
        if (moment(inputDateString, 'MM/DD/YYYY', true).isValid()) {
          const originalDate = moment(inputDateString, 'MM/DD/YYYY');
          formattedDate = originalDate.format('DD/MM/YYYY');
        } else {
          const originalDate = moment(inputDateString, 'MMMM Do YYYY, h:mm a');
          formattedDate = originalDate.format('DD/MM/YYYY');
        }

        return formattedDate;
      } else {
        return ''
      }
    },

    handleUserSelectedWatchList(value) {
      this.currentWatchList = value;
      this.closeFooter()
      this.checkAllRecordsFlag = false;
      this.userSelectedTableRecords = [];
      // this.selectedWatchList=value,
      // console.log("selectedwatch",this.selectedWatchList)
      this.selectedWatchListID = value._id;
      this.selectedWatchListuserID = value.userId;
      this.selectedWatchListuserName = value.name;

      console.log("selected==>", value.createdAt, value.updatedAt)

      this.createdAt = this.formateDateToDMY(value.createdAt);
      this.updatedAt = this.formateDateToDMY(value.updatedAt);
      this.selectedWatchItemCount = value.symbolList?.length;
      // console.log("currentWatchList.watchId",this.currentWatchList._id)
      // this.fetchSingleWatchList(this.currentWatchList._id)
      this.watchListTableData = this.watchListApi_Data.filter(
        (item) => item._id === value._id
      );
      console.log("watchlist table data", this.watchListTableData);
    },

    handleUpdatedWatchListSymbols() {
      let index = this.watchListApi_Data.findIndex(
        (x) => x == this.currentWatchList
      );
      // console.log("index",index)
      this.fetchwatchListApi_Data(index);
    },

    handleCreateWatchList() {
      this.isFooter = false
      this.fetchwatchListApi_Data(this.watchListApi_Data.length);
    },

    handleRemoveWatchList: async function () {
      if (this.selectedWatchListID && this.selectedWatchListuserID) {
        try {
          const payload = {
            userId: this.selectedWatchListuserID,
            watchId: this.selectedWatchListID,
          };
          const res = await watchListApi.deleteWatchList(payload);
          if (res) {
            this.removeWatchList = false;

            // console.log(res);
            // console.log("this.watchListApi_Data.length", this.watchListApi_Data.length);
            // if (!res.data.isError) {
            let index = this.watchListApi_Data.findIndex(
              (x) => x == this.currentWatchList
            );
            if (index == 0) {
              this.fetchwatchListApi_Data(index);
            } else {
              this.fetchwatchListApi_Data(index - 1);
            }
            // let ind = this.watchListApi_Data.length == 1 ? 0 : this.watchListApi_Data.length - 2
          }
          // }
        } catch (err) {
          console.log("api call failed");
        }
      } else {
        console.log("no dates selected");
      }
    },
    fetchSingleWatchList: async function (value) {
      console.log("value from api", value);
      const response = await watchListApi.fetchSingleWatchListData(value);
      console.log("single watchlist:", response);
    },



    async getTableColumnList() {
      try {
        const res = await watchListApi.getTableColumnList()
        if (res?.data?.success) {
          if (res?.data?.payload?.length > 0) {
            this.userSetColumnList = res?.data?.payload
            this.selectedColumnList = res?.data?.payload[0]
            this.newTableColumn = res?.data?.payload[0]?.columnList
            localStorage.setItem("tableColumns", JSON.stringify(this.newTableColumn));


            // const req = await watchListApi.getLastColumn()
            // if(req?.data?.success){
            //   const key = req?.data?.payload
            //   const filterData = res?.data?.payload?.find((item)=>item?._id==key)
            //   if(filterData){
            //     this.selectedColumnList = filterData
            //     this.newTableColumn = filterData?.columnList
            //     localStorage.setItem("tableColumns", JSON.stringify(this.newTableColumn));
            //   }
            // }

          } else {
            this.userSetColumnList = []
            this.selectedColumnList = {}
            this.newTableColumn = this.defaultColumnList
            localStorage.setItem("tableColumns", JSON.stringify(this.newTableColumn));
          }
        }
      }
      catch (err) {
        console.log("err")
      }

    },

    closeColumnListModal() {
      this.openSaveColumnModal = false
      this.getTableColumnList()
    },


    toggleModal() {
      this.createWatchListState = false;
      this.addSymbolsState = false;
      this.removeWatchList = false;
      this.removeSymbol = false;
      this.addColumnsState = false;
      this.openSaveColumnModal = false
      this.openEditColumnModal = false
    },
  },
  mounted() {


    let x = localStorage.getItem("tableColumns");
    this.newTableColumn = JSON.parse(x);
    console.log("tableColumns", this.newTableColumn);

    this.getDropDownList()

    // console.log("chartHeight: " + this.chartHeight)
    // console.log("fetch data");
    this.fetchwatchListApi_Data();
    console.log("watchListTableData[0].symbolList", this.watchListTableData[0]?.symbolList)
    // console.log("table data",this.watchListTableData)
    // console.log("api response");
    window.addEventListener("resize", this.onResize);
    this.watchListChartHeight = this.$refs.areaChartHeightDiv.clientHeight - 60;
    this.getTableColumnList()

    // owais
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },


  watch: {
    newTableColumn: function (val) {
      console.log("new column", val)
    },
    isAllColumn: function (val) {
      if (val) {
        this.newTableColumn = this.WatchConfig
        localStorage.setItem("tableColumns", JSON.stringify(this.newTableColumn));
      } else {
        let symbolCol = this.WatchConfig.find((item) => item.colName == "symbol")
        this.newTableColumn = [symbolCol]
        localStorage.setItem("tableColumns", JSON.stringify(this.newTableColumn));
      }
    },
    isAllEditColumn: function (val) {
      if (val) {
        this.selectedColumnListData = this.WatchConfig
      } else {
        let val = this.WatchConfig.find((item) => item.colName == "symbol")
        this.selectedColumnListData = [val]
      }
    },

    modalSuccessMessage: function () {
      setTimeout(() => {
        this.modalSuccessMessage = null
      }, 3000);
    },
    modalErrorMessage: function () {
      setTimeout(() => {
        this.modalErrorMessage = null
      }, 3000);
    },


    watchListApi_Data: function (val) {


      if (val.length === this.signedInUser.watchlist) {
        this.isCreateWatchList = true;
      } else {
        this.isCreateWatchList = false;
      }
    },
    watchListTableData: function (val) {
      this.watchlistTableDataList = val[0]?.symbolList
      console.log("value", this.watchlistTableDataList)
    },

    tableColumns: function () {
      let localData = localStorage.getItem("tableColumns");
      this.newTableColumn = JSON.parse(localData);
      console.log("tableColumns", this.newTableColumn);
    },
    // owais
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200&display=swap");

::-webkit-scrollbar {
  width: 10px !important;
  height: 10px !important
}

.setting-column:hover {
  stroke: #9fa5a2;
}

.remove-button:hover {
  background: linear-gradient(270deg,
      rgba(255, 57, 57, 0.24) 0%,
      rgba(255, 70, 64, 0) 50%,
      rgba(248, 55, 42, 0.24) 100%);
}

.deleteButton {
  background: linear-gradient(270deg,
      rgba(255, 57, 57, 0.24) 0%,
      rgba(255, 70, 64, 0) 50%,
      rgba(248, 55, 42, 0.24) 100%);
}

.red-button {
  border: 0.6px solid #FF0A00;
  border-radius: 3px;
  color: #FF0A00;
  text-transform: uppercase;
}

.green-button {
  border: 0.6px solid #FF0A00;
  border-radius: 3px;
  color: #FF0A00;
  text-transform: uppercase;
}

.red-button:hover {
  background: linear-gradient(270deg,
      rgba(255, 57, 57, 0.24) 0%,
      rgba(255, 70, 64, 0) 50%,
      rgba(248, 55, 42, 0.24) 100%);
}

.gray-button {
  border: 0.6px solid #f8f9f9;
  border-radius: 3px;
  color: #f8f9f9;
  text-transform: uppercase;
}

.gray-button:hover {
  background: linear-gradient(270deg,
      rgba(248, 249, 249, 0.21) 0%,
      rgba(248, 249, 249, 0) 52.08%,
      rgba(248, 249, 249, 0.21) 100%);
}

.ok-button:hover {
  background: linear-gradient(270deg,
      rgba(2, 189, 45, 0.24) 0%,
      rgba(2, 189, 45, 0) 50%,
      rgba(2, 189, 45, 0.24) 100%);
}

.watchlist-button:hover {
  background: linear-gradient(270deg,
      rgba(248, 249, 249, 0.21) 0%,
      rgba(248, 249, 249, 0) 52.08%,
      rgba(248, 249, 249, 0.21) 100%);
}

.create-watch-btn:hover {
  text-decoration: underline;
}

.overlay__content {
  margin-top: 10vh;
  transform: translateX(400%);
}

.spinner {
  width: 75px;
  height: 75px;
  display: inline-block;
  border-width: 2px;
  border-color: rgba(255, 255, 255, 0.05);
  border-top-color: #fff;
  animation: spin 1s infinite linear;
  border-radius: 100%;
  border-style: solid;
}

.apexChartdiv .apexcharts-canvas {
  /* top: 35px; */
}

tbody tr:hover {
  /* z-index: 105; */
  /* background-color: white !important; */

  /* opacity: .5; */
  /* filter: brightness(500%); */
}

tbody tr td {
  /* justify-content: center;
    align-items: center;
    text-align: center; */
}

.actionBtn {
  padding: 0.3rem 1.5rem 0.3rem 1.5rem;
}


.container {
  display: block;
  position: relative;
  left: 4px;
  cursor: pointer;
  width: 0.7em;
  height: 0.7em;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;

  width: 0.8vw;
  height: 1.4vh;
  border: 0.1vw solid #828282;
  border-radius: 0.1vw;
  background-color: #151515;
}

.checkmark_column {
  position: absolute;
  top: 0;
  left: 0;
  width: 0.8vw;
  height: 1.4vh;
  border: 0.1vw solid #828282;
  border-radius: 0.1vw;
  background-color: white;
}

.container:hover input~.checkmark {
  background: linear-gradient(270deg,
      rgba(2, 189, 45, 0.24) 0%,
      rgba(2, 189, 45, 0) 50%,
      rgba(2, 189, 45, 0.24) 100%);
}

.greenHover:hover {
  background: linear-gradient(270deg,
      rgba(2, 189, 45, 0.24) 0%,
      rgba(2, 189, 45, 0) 50%,
      rgba(2, 189, 45, 0.24) 100%);
}

.overlay__content {
  margin-top: 30vh;
  transform: translate(750%, 10%);
}

.spinner {
  width: 75px;
  height: 75px;
  display: inline-block;
  border-width: 2px;
  border-color: rgba(255, 255, 255, 0.05);
  border-top-color: #fff;
  animation: spin 1s infinite linear;
  border-radius: 100%;
  border-style: solid;
}

.container input:checked~.checkmark {
  background-color: rgba(0, 201, 46, 0.12);
  border: 0.1vw solid #00c92e;
  border-radius: 0.1vw;
}

.container input:checked~.checkmark_column {
  background-color: rgba(0, 201, 46, 0.01);
  border: 0.1vw solid #00c92e;
  border-radius: 0.1vw;
}

.checkmark:after {
  content: "";
  position: absolute;

  display: none;
}

.checkmark_column:after {
  content: "";
  position: absolute;

  display: none;
}

.container input:checked~.checkmark:after {
  display: block;
}

.container input:checked~.checkmark_column:after {
  display: block;
}

.container .checkmark:after {
  left: 0.19vw;
  bottom: 0.19vh;
  width: 33%;
  height: 70%;
  border: solid #00c92e;
  border-width: 0 0.12vw 0.12vw 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.container .checkmark_column:after {
  left: 0.19vw;
  bottom: 0.19vh;
  width: 33%;
  height: 70%;
  border: solid #00c92e;
  border-width: 0 0.12vw 0.12vw 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.footerWidth {
  width: 81.6%
}

/* NOOO */
/* :checked::before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #e5e9ec;
  opacity: 0.2;
  z-index: 999;
} */

.disabled {
  opacity: 0.3;
}

.symbolLeft {
  left: 5rem;
}

.chartLeft {
  left: 2.5rem;
}

@media screen and (max-width: 1500px) {
  .marketwatchTableHeight {
    height: 40vh;
  }

}

@media screen and (max-width: 1550px) {
  .actionBtn {
    padding: 0.3rem 1rem 0.3rem 1rem;
  }

}

@media screen and (max-width: 1500px) {
  .marketwatchTableHeight {
    height: 37vh;
  }
}

@media screen and (max-width: 1600px) {
  .footerWidth {
    width: 81.17%
  }

  .symbolLeft {
    left: 4.5rem;
  }

  .chartLeft {
    left: 2.3rem;
  }
}
</style>
